//* ======= Libraries
import React, { useState, useEffect, useMemo } from 'react'
import { PartialDeep } from 'type-fest'
import { Stack, Box, Typography, Tabs, Tab, TooltipProps, IconButton } from '@mui/material'
//* ======= Components and features
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import FontPicker from 'components/widgets/FontPicker'
import ColorPicker from 'components/widgets/ColorPicker'
import BorderPicker from 'components/widgets/BorderPicker'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import BaseSwitch from 'components/base/BaseSwitch'
import BaseButton from 'components/base/BaseButton'
//* ======= Custom logic
import useReportStore, { useReportActiveWidget } from 'features/report-designer/store/reportDesignerStore'
import {
    ReportWidgetType,
    ReportWidgetContentKindType,
    InfoWidgetType,
    ReportWidgetSettingsBaseType,
} from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'

const iconTypeOptions: { label: string; value: InfoWidgetType['icon']['type'] }[] = [
    {
        label: 'Exclamation Mark',
        value: 'exclamation',
    },
    {
        label: 'Question Mark',
        value: 'question',
    },
    {
        label: 'Question Mark Outlined',
        value: 'questionOutline',
    },
    {
        label: 'Video',
        value: 'video',
    },
    {
        label: 'Premium ',
        value: 'premium',
    },
]

export const iconSizeDict: Record<InfoWidgetType['icon']['size'], number> = {
    small: 18,
    medium: 24,
    large: 32,
}

const iconSizeOptions: { label: string; value: InfoWidgetType['icon']['size'] }[] = [
    {
        label: 'Small',
        value: 'small',
    },
    {
        label: 'Medium',
        value: 'medium',
    },
    {
        label: 'Large',
        value: 'large',
    },
]

export const tooltipPlacementOptions: TooltipProps['placement'][] = [
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
]

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'info'>
    settings: Omit<ReportWidgetType, 'content'>
    details: InfoWidgetType
}

function InfoWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const { activeSlideId, updateWidget, updateWidgetStyles, updateInfoWidgetContent } = useReportStore((store) => ({
        activeSlideId: store.activeSlideId,
        updateWidget: store.updateWidget,
        updateWidgetStyles: store.updateWidgetStyles,
        updateInfoWidgetContent: store.updateInfoWidgetContent,
    }))

    const activeWidget = useReportActiveWidget(widgetId)

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'info') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const [currentTabIndex, setCurrentTabIndex] = useState(1)

    const [sourceInputValue, setSourceInputValue] = useState('')

    // Update and any dependant states on activeWidget change.
    useEffect(() => {
        if (activeWidget !== null && activeWidget.content.kind === 'info') {
            const {
                content: { details },
            } = activeWidget

            setSourceInputValue(details.video.source)
        }
    }, [activeWidget])

    const updateStyles = (styles: PartialDeep<ReportWidgetType['styles']>) => {
        if (activeSlideId !== null && widget !== null) {
            updateWidgetStyles({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                styles: styles,
            })
        }
    }

    const updateDetails = (details: PartialDeep<InfoWidgetType>) => {
        if (activeSlideId !== null && widget !== null) {
            updateInfoWidgetContent({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                data: details,
            })
        }
    }

    const onSizeChange = (size: InfoWidgetType['icon']['size']) => {
        if (activeSlideId !== null && widget !== null) {
            updateWidget({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                data: {
                    dimensions: {
                        width: iconSizeDict[size],
                        height: iconSizeDict[size],
                    },
                    content: {
                        kind: widget.kind,
                        details: {
                            icon: {
                                size: size,
                            },
                        },
                    },
                },
            })
        }
    }

    const onVideoSourceUpdate = (source: InfoWidgetType['video']['source']) => {
        if (source.trim() !== '') {
            updateDetails({
                tooltip: {
                    shouldShowOnHover: false,
                    backgroundColor: {
                        isEnabled: false,
                    },
                },
                video: {
                    source: sourceInputValue.trim(),
                },
            })
        } else {
            updateDetails({
                video: {
                    source: '',
                },
            })
        }
    }

    return (
        <>
            {activeSlideId !== null && widget !== null ? (
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                    })}
                >
                    {/* Tabs
                        ========================================= */}
                    {isInternal === false && (
                        <Box
                            sx={{
                                flexShrink: 0,

                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tabs
                                value={currentTabIndex}
                                onChange={(evt, currentIndex) => setCurrentTabIndex(currentIndex)}
                                variant="fullWidth"
                            >
                                <Tab label="Container" />
                                <Tab label="Info" />
                            </Tabs>
                        </Box>
                    )}

                    {/* Tab panels
                        ========================================= */}
                    {currentTabIndex === 0 ? (
                        /*  Container settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Title
                                ========================================= */}
                            <BaseFilledTextField
                                label="Widget Title"
                                defaultValue={widget.settings.title}
                                onBlur={(evt) =>
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            title: evt.target.value.trim(),
                                        },
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Background color
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Background Color"
                                hasToggle
                                isToggledOff={widget.settings.styles.backgroundColor.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        backgroundColor: {
                                            isEnabled: isEnabled,
                                            color: widget.settings.styles.backgroundColor.color ?? '#ffffff',
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <ColorPicker
                                    value={widget.settings.styles.backgroundColor.color}
                                    onChange={(newColor) =>
                                        updateStyles({
                                            backgroundColor: {
                                                color: newColor,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.backgroundColor.isEnabled === false}
                                    isPopover={true}
                                />
                            </StyledWidgetAccordion>

                            {/* Border
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Border"
                                hasToggle
                                isToggledOff={widget.settings.styles.border.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        border: {
                                            isEnabled: isEnabled,
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <BorderPicker
                                    value={widget.settings.styles.border}
                                    onChange={(value) =>
                                        updateStyles({
                                            border: {
                                                width: value.width,
                                                style: value.style,
                                                color: value.color,
                                                radius: value.radius,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.border.isEnabled === false}
                                />
                            </StyledWidgetAccordion>
                        </Stack>
                    ) : currentTabIndex === 1 ? (
                        /*  Content settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Icon
                                ========================================= */}
                            <StyledWidgetAccordion title="Icon" hasBottomBorder>
                                <Stack gap={1}>
                                    {/* Icon type
                                        ========================================= */}
                                    <BaseSelectWithLabel
                                        label="Type"
                                        options={iconTypeOptions}
                                        value={widget.details.icon.type}
                                        onChange={(value) =>
                                            updateDetails({
                                                icon: {
                                                    type: value,
                                                },
                                            })
                                        }
                                        size="small"
                                    />

                                    {/* Icon size
                                        ========================================= */}
                                    <BaseSelectWithLabel
                                        label="Size"
                                        options={iconSizeOptions}
                                        value={widget.details.icon.size}
                                        onChange={(value: InfoWidgetType['icon']['size']) => onSizeChange(value)}
                                        size="small"
                                    />

                                    {/* Icon color
                                        ========================================= */}
                                    <ColorPicker
                                        value={widget.details.icon.color}
                                        onChange={(newColor) =>
                                            updateDetails({
                                                icon: {
                                                    color: newColor,
                                                },
                                            })
                                        }
                                        isPopover={true}
                                    />
                                </Stack>
                            </StyledWidgetAccordion>

                            {/* Text
                                ========================================= */}
                            <StyledWidgetAccordion title="Text" hasBottomBorder>
                                <Stack gap={1}>
                                    {/* Value
                                        ========================================= */}
                                    <BaseFilledTextField
                                        label="Message"
                                        defaultValue={widget.details.text.value}
                                        onBlur={(evt) =>
                                            updateDetails({
                                                text: {
                                                    value: evt.target.value.trim(),
                                                },
                                            })
                                        }
                                        multiline
                                        rows={5}
                                        size="small"
                                        fullWidth
                                    />

                                    {/* Font
                                        ========================================= */}
                                    <FontPicker
                                        onChange={(values) =>
                                            updateDetails({
                                                text: {
                                                    fontFamily: values.fontFamily,
                                                    fontSize: values.fontSize,
                                                    color: values.color,
                                                },
                                            })
                                        }
                                        defaultValue={{
                                            fontFamily: widget.details.text.fontFamily,
                                            fontSize: widget.details.text.fontSize,
                                            color: widget.details.text.color,
                                        }}
                                        styleControls={false}
                                    />
                                </Stack>
                            </StyledWidgetAccordion>

                            {/* Video
                                ========================================= */}
                            <StyledWidgetAccordion title="Video" hasBottomBorder>
                                <Stack gap={1}>
                                    <Typography fontSize={14} noWrap textAlign="left">
                                        Source URL:
                                    </Typography>

                                    {/* Source
                                        ========================================= */}
                                    <BaseFilledTextField
                                        value={sourceInputValue}
                                        onChange={(evt) => setSourceInputValue(evt.target.value)}
                                        onKeyUp={(evt) => {
                                            if (evt.key === 'Enter') {
                                                onVideoSourceUpdate(sourceInputValue.trim())
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment:
                                                widget.details.video.source !== '' ? (
                                                    <IconButton onClick={(evt) => onVideoSourceUpdate('')}>
                                                        <ClearRoundedIcon
                                                            sx={{
                                                                width: 22,
                                                                height: 22,
                                                            }}
                                                        />
                                                    </IconButton>
                                                ) : undefined,
                                        }}
                                        size="small"
                                        hiddenLabel
                                        fullWidth
                                        sx={(theme) => ({
                                            '& .MuiInputBase-adornedEnd': {
                                                paddingRight: theme.spacing(0.5),
                                            },
                                        })}
                                    />

                                    {/* Set source button
                                        ========================================= */}
                                    <BaseButton
                                        label="Set Source"
                                        onClick={(evt) => onVideoSourceUpdate(sourceInputValue.trim())}
                                        disabled={sourceInputValue.trim() === ''}
                                        variant="outlined"
                                        sx={{
                                            alignSelf: 'center',

                                            paddingY: 1,
                                            paddingX: 4,
                                        }}
                                    />
                                </Stack>
                            </StyledWidgetAccordion>

                            {/* Tooltip
                                ========================================= */}
                            <StyledWidgetAccordion title="Tooltip" hasBottomBorder>
                                <Stack gap={2}>
                                    {/* "disabled" status message
                                        ========================================= */}
                                    {widget.details.video.source !== '' && (
                                        <Typography fontSize={14} textAlign="center" color="secondary.light">
                                            (Disabled because video is set.)
                                        </Typography>
                                    )}

                                    {/* Placement
                                        ========================================= */}
                                    <BaseSelectWithLabel
                                        label="Placement"
                                        options={tooltipPlacementOptions}
                                        value={widget.details.tooltip.placement}
                                        onChange={(value) =>
                                            updateDetails({
                                                tooltip: {
                                                    placement: value,
                                                },
                                            })
                                        }
                                        disabled={widget.details.video.source !== ''}
                                        size="small"
                                    />

                                    {/* Show on hover switch
                                        ========================================= */}
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                        <Typography
                                            fontSize={14}
                                            noWrap
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        >
                                            Show on hover:
                                        </Typography>

                                        <BaseSwitch
                                            checked={widget.details.tooltip.shouldShowOnHover}
                                            onChange={(evt, checked) =>
                                                updateDetails({
                                                    tooltip: {
                                                        shouldShowOnHover: checked,
                                                    },
                                                })
                                            }
                                            disabled={widget.details.video.source !== ''}
                                            size="small"
                                            color="primary"
                                            sx={{
                                                flexShrink: 0,
                                            }}
                                        />
                                    </Stack>

                                    {/* Background switch
                                        ========================================= */}
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                        <Typography
                                            fontSize={14}
                                            noWrap
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        >
                                            Background color:
                                        </Typography>

                                        <BaseSwitch
                                            checked={widget.details.tooltip.backgroundColor.isEnabled}
                                            onChange={(evt, checked) =>
                                                updateDetails({
                                                    tooltip: {
                                                        backgroundColor: {
                                                            isEnabled: checked,
                                                        },
                                                    },
                                                })
                                            }
                                            disabled={widget.details.video.source !== ''}
                                            size="small"
                                            color="primary"
                                            sx={{
                                                flexShrink: 0,
                                            }}
                                        />
                                    </Stack>

                                    {/* Background color
                                        ========================================= */}
                                    <ColorPicker
                                        value={widget.details.tooltip.backgroundColor.color}
                                        onChange={(newColor) =>
                                            updateDetails({
                                                tooltip: {
                                                    backgroundColor: {
                                                        color: newColor,
                                                    },
                                                },
                                            })
                                        }
                                        disabled={
                                            widget.details.video.source !== '' ||
                                            widget.details.tooltip.backgroundColor.isEnabled === false
                                        }
                                        isPopover={true}
                                    />
                                </Stack>
                            </StyledWidgetAccordion>
                        </Stack>
                    ) : (
                        false
                    )}
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default InfoWidgetSettings
