import { DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import StyledDialog from 'components/dialog/StyledDialog'
import { useRef, useState } from 'react'
import { AllocationResult } from 'services/AllocationApi'

type ImportAllocationProps = {
    open: boolean
    onClose: () => void
    onImport: (result: AllocationResult) => void
}

const ImportAllocationDialog: React.FC<ImportAllocationProps> = ({ open, onClose, onImport }) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [file, setFile] = useState<File | null>(null)

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0])
        }
    }

    const handleImport = async () => {
        if (file === null) return

        // read file
        const reader = new FileReader()
        reader.onload = async (e) => {
            if (e.target?.result) {
                const content: any = JSON.parse(e.target.result as string)
                onImport(content)
                onClose()
            }
        }
        reader.readAsText(file)
    }

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Import Allocation</DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Typography variant="body2">Import a previously exported allocation file.</Typography>
                    <BaseButton
                        variant="outlined"
                        color="primary"
                        label="Choose a file"
                        onClick={() => {
                            if (inputRef.current) {
                                inputRef.current.click()
                            }
                        }}
                    />
                    <input
                        style={{ display: 'none' }}
                        ref={inputRef}
                        type="file"
                        onChange={handleFileChange}
                        accept=".json"
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose}>Cancel</BaseButton>
                <BaseButton disabled={file === null} onClick={handleImport}>
                    Import
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default ImportAllocationDialog
