import { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import * as echarts from 'echarts'
import { debounce } from 'helpers/helpers'
import {
    EdgeArrowType,
    EdgeRenderType,
    GraphActionType,
    NetworkSelectedItemType,
    NodeGroupInfoType,
    NodeIdType,
    NodeRenderType,
} from '../../types/NetworkViz.types'
import usePreviousValue from 'hooks/usePreviousValue'
import useDebouncedResizeObserver from 'hooks/useDebouncedResizeObserver'
import { convertImagesToBase64 } from 'features/network-viz/helpers/NetworkViz.helper'

const DEBOUNCE_DELAY = 200
const INITIAL_ZOOM_LEVEL = 1
const WINDOW_RESIZE_EVENT = 'resize'
const GRAPH_ROAM_EVENT = 'graphroam'
const GRAPH_NODE_CLICK = 'click'
const GRAPH_NODE_MOUSEOVER = 'mouseover'
const GRAPH_NODE_MOUSEOUT = 'mouseout'
const PNG_EXTENSION = '.png'
const SVG_EXTENSION = '.svg'
const EXPORT_IMAGE_TYPE_SVG = 'svg'
const CHART_DEFAULT_Z_INDEX = 999
const CHART_DEFAULT_POSITION = 'fixed'
const CHART_DEFAULT_LAYOUT = 'none'
const CHART_DEFAULT_REPULSION = 50
const CHART_DEFAULT_GRAVITY = 0.1
const CHART_DEFAULT_EDGE_LENGTH = 30
const CHART_DEFAULT_LAYOUT_ANIMATION = false
const CHART_DEFAULT_FRICTION = 0.6
const CHART_DEFAULT_LINE_WIDTH = 0.3
const CHART_DEFAULT_LINE_COLOR = '#000000'

export interface SNAGraph {
    action: GraphActionType | null
    nodes: NodeRenderType[]
    nodeGroupInfo?: NodeGroupInfoType
    edges: EdgeRenderType[]
    setSelectedItem: (selectedItem: NetworkSelectedItemType) => void
    selectedNode: NodeIdType | null
    edgeSymbol?: EdgeArrowType
    chartRef?: RefObject<HTMLDivElement>
    redraw?: number
    updateNodeLocation?: (id: string, x: number, y: number) => void
    onNodeHover: (id: NodeIdType | null) => void
}

// The NetworkEcharts component displays a network visualization using Echarts.
const NetworkEcharts = ({
    action,
    nodes,
    edges,
    nodeGroupInfo,
    // zoom,
    redraw,
    chartRef,
    setSelectedItem,
    selectedNode,
    onNodeHover,
    edgeSymbol = { arrow: { source: 'none', target: 'arrow' }, size: 10 },
}: SNAGraph) => {
    // React hooks to manage local state and side effects
    const containerSize = useDebouncedResizeObserver({ wait: DEBOUNCE_DELAY, ref: chartRef })

    const lastActionId = usePreviousValue(action?.id || 0)
    const myChart = useRef<echarts.EChartsType | null>(null)
    const [graphZoom, setGraphZoom] = useState<number>(INITIAL_ZOOM_LEVEL)

    // Callback function to resize the chart when needed.
    const resizeChart = useCallback(() => {
        myChart.current?.resize()
    }, [myChart])

    // Effect to resize the chart on container size change
    useEffect(() => {
        if (!myChart.current) {
            return
        }
        myChart.current.resize()
    }, [containerSize])

    // Handler functions for node interactions (select, hover, mouseout)

    const nodeSelectHandler = (e: any) => {
        setSelectedItem({
            mode: Array.isArray(e.data.innerNodes) && e.data.innerNodes.length > 0 ? 'group' : 'node',
            id: e.data.id,
        })
    }

    const edgeSelectHandler = (e: any) => {
        setSelectedItem({
            mode: 'edge',
            id: e.data.id,
        })
    }

    const nodeMouseoverHandler = (e: any) => {
        onNodeHover(e?.data?.id || null)
        return false
    }

    const nodeMouseoutHandler = (e: any) => {
        onNodeHover(null)
        return false
    }

    // Debounce function to reduce the frequency of zoom handler calls
    var zoomHandler = debounce((zoom: number) => {
        setGraphZoom(zoom)
    }, DEBOUNCE_DELAY)

    // Effect to initialize the chart instance and bind events
    useEffect(() => {
        if (!myChart.current && chartRef?.current) {
            const chart = echarts.init(chartRef.current as HTMLElement)

            // select a node when its clicked
            chart.on(GRAPH_NODE_CLICK, { dataType: 'node' }, nodeSelectHandler)
            chart.on(GRAPH_NODE_CLICK, { dataType: 'edge' }, edgeSelectHandler)

            // apply on hover logic when on node hover
            chart.on(GRAPH_NODE_MOUSEOVER, { dataType: 'node' }, nodeMouseoverHandler)

            chart.on(GRAPH_NODE_MOUSEOUT, { dataType: 'node' }, nodeMouseoutHandler)

            // chart.on(GRAPH_ROAM_EVENT, () => {
            //     const option = chart.getOption() as echarts.EChartsOption
            //     if (option.series) {
            //         const seriesOption = Array.isArray(option.series) ? option.series[0] : option.series
            //         const graphSeriesOption = seriesOption as any
            //         if ('zoom' in graphSeriesOption) {
            //             zoomHandler(graphSeriesOption.zoom)
            //         }
            //     }
            // })

            window.addEventListener(WINDOW_RESIZE_EVENT, resizeChart)
            myChart.current = chart

            return () => {
                window.removeEventListener(WINDOW_RESIZE_EVENT, resizeChart)
                chart.off(GRAPH_NODE_CLICK, nodeSelectHandler)
                chart.off(GRAPH_NODE_MOUSEOVER, nodeMouseoverHandler)
                chart.off(GRAPH_NODE_MOUSEOUT, nodeMouseoutHandler)
                myChart.current = null
                chart.dispose()
            }
        }
    }, [])

    // Effect to resize the chart whenever the redraw value changes
    useEffect(() => {
        myChart.current?.resize()
    }, [redraw])

    // Effect to listen for action events and execute associated logic
    useEffect(() => {
        if (action && action.id > 0 && action.id !== lastActionId) {
            switch (action.content.type) {
                case 'fit':
                    if (myChart.current) {
                        myChart.current.setOption({
                            series: [
                                {
                                    zoom: INITIAL_ZOOM_LEVEL,
                                    center: undefined,
                                },
                            ],
                        })
                        setGraphZoom(INITIAL_ZOOM_LEVEL)
                    }
                    break
                case 'export-image':
                    doExportImage(action.content.exportFormat)
            }
        }
    }, [action?.id])

    const doExportImage = async (type: 'svg' | 'png') => {
        if (type === EXPORT_IMAGE_TYPE_SVG) {
            await saveImageSVG()
        } else {
            await saveImagePNG()
        }
    }

    // Functions to export the network visualization as SVG or PNG image

    const saveImageSVG = async () => {
        const chart = createNewEchart('svg')
        chart.setOption(await buildChartOption())

        const link = document.createElement('a')
        link.download = 'img' + SVG_EXTENSION
        link.href = chart.getDataURL()
        document.body.appendChild(link)
        link.click()
        removeElementsFromDocument([link, chart.getDom()])
    }

    const saveImagePNG = async () => {
        const chart = createNewEchart('canvas')
        chart.setOption(await buildChartOption())

        chart.on('finished', function () {
            const link = document.createElement('a')
            link.download = 'img' + PNG_EXTENSION
            link.href = chart.getDataURL()
            document.body.appendChild(link)
            link.click()
            removeElementsFromDocument([link, chart.getDom()])
        })
    }

    const createNewEchart = (renderer: 'canvas' | 'svg') => {
        const div = document.createElement('div')
        div.setAttribute(
            'style',
            `opacity:0;width:100%;height:100%;position:${CHART_DEFAULT_POSITION};zIndex:${CHART_DEFAULT_Z_INDEX};top:0;left:0`
        )
        document.body.appendChild(div)
        return echarts.init(div, undefined, { renderer })
    }

    const removeElementsFromDocument = (elements: HTMLElement[]) => {
        elements.forEach((element) => document.body.removeChild(element))
    }

    const buildChartOption = async () => ({
        series: [
            {
                layout: CHART_DEFAULT_LAYOUT,
                force: {
                    repulsion: CHART_DEFAULT_REPULSION,
                    gravity: CHART_DEFAULT_GRAVITY,
                    edgeLength: CHART_DEFAULT_EDGE_LENGTH,
                    layoutAnimation: CHART_DEFAULT_LAYOUT_ANIMATION,
                    friction: CHART_DEFAULT_FRICTION,
                },
                type: 'graph',
                data: await convertImagesToBase64(nodes),
                links: edges,
                selectedMode: 'single',
                autoCurveness: true,
                roam: true,
                draggable: true,
                symbolSize: 1,
                lineStyle: {
                    width: CHART_DEFAULT_LINE_WIDTH,
                    color: CHART_DEFAULT_LINE_COLOR,
                },
            },
        ],
    })

    // Effect to update the chart options when the graph state changes
    useEffect(() => {
        if (!myChart.current) return
        myChart.current.setOption({
            tooltip: {
                formatter: (params: any) => {
                    return params.data.tooltip
                },
            },
            series: [
                {
                    layout: CHART_DEFAULT_LAYOUT,
                    type: 'graph',
                    labelLayout: {
                        hideOverlap: true,
                    },
                    label: {
                        position: 'right',
                    },
                    data: nodes,
                    links: edges.map((edge) => ({
                        ...edge,
                        symbol: null,
                        symbolSize: null,
                    })),
                    autoCurveness: true,
                    roam: true,
                    draggable: false,
                },
            ],
            animation: false,
        })

        myChart.current.setOption({
            series: [
                {
                    links: edges,
                },
            ],
            animation: false,
        })
    }, [myChart, nodes, edges, edgeSymbol])

    // The component returns an empty fragment because it doesn't render any JSX
    // All rendering is done by the Echarts library.
    return <></>
}

export default NetworkEcharts
