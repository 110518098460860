import React, { useMemo } from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import { useNetworkVizContext } from '../context/NetworkVizContext'
import { groupBy, uniq } from 'lodash'
import CircleIcon from '@mui/icons-material/Circle'
import { NodeAttributeType } from '../types/NetworkViz.types'

type NodeLegendItemType = {
    title: string
    color: string
    active: boolean
}

const NetworNodeLegend: React.FC = () => {
    const { nodeStyle, nodeRenders, nodeGroupBy } = useNetworkVizContext()

    const groups: NodeLegendItemType[] = useMemo(() => {
        const result: NodeLegendItemType[] = []
        const visibleNodes = uniq(nodeRenders.filter((x) => x.hide !== true).map((render) => render.groupKey))

        visibleNodes.forEach((name) => {
            if (name === undefined || name === 'default') return
            result.push({
                title: name,
                color: nodeStyle[name].normal.color,
                active: nodeStyle[name].visible,
            })
        })
        return result.sort((a, b) => (a.title > b.title ? 1 : -1))
    }, [nodeStyle, nodeRenders])

    // const toggleRelation = (index: number) => {
    //     dispatchContext({
    //         type: 'EDGE_STYLE_VISSIBLE_TOGGLE',
    //         payload: {
    //             name: groups[index].title,
    //         },
    //     })
    // }

    const captalizeFirstLetter = (str: string) => {
        if (str == null || str === '') return ''
        return str.charAt(0).toUpperCase() + str.slice(1)
    }

    const generateGroupByLabel = (attr: NodeAttributeType) => {
        switch (attr.source) {
            case 'analytic':
                var filedName = attr.field
                if (attr.field === 'greedy_modularity_community') {
                    filedName = 'Group'
                }

                var relationship = attr.relationship

                if (relationship === 'friends') {
                    relationship = 'Friendship'
                }

                return captalizeFirstLetter(`${relationship} ${filedName}`)
            case 'info':
                return captalizeFirstLetter(attr.field)
        }
        return ''
    }

    const generateItemTitle = (value: any, attr: NodeAttributeType) => {
        switch (attr.source) {
            case 'analytic':
                if (attr.field === 'greedy_modularity_community') {
                    if (value === 'unknown') {
                        return 'No Group'
                    }
                    return `Group ${value}`
                }
                return value
            case 'info':
                if (attr.field === 'gender') {
                    if (value === '1') {
                        return 'Boy'
                    } else if (value === '2') {
                        return 'Girl'
                    } else if (value === '3') {
                        return 'Gender Diverse'
                    } else {
                        return 'Unknown'
                    }
                }
                return value
        }
        return ''
    }

    return (
        <Stack
            sx={(theme) => ({
                display: 'flex',
                overflowX: 'hidden',
                flex: 1,
                alignItems: 'flex-start',
                height: '100%',
                pointerEvents: 'auto',
            })}
            direction="column"
            gap={1}
            className="u-scrollbar"
        >
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 600,
                }}
            >
                {nodeGroupBy.map((x) => generateGroupByLabel(x)).join(',')}
            </Typography>
            {groups.map((relation, index) => (
                <Stack
                    key={index}
                    direction="row"
                    gap={1}
                    alignItems="center"
                    sx={{
                        cursor: 'pointer',
                    }}
                    // onClick={() => toggleRelation(index)}
                >
                    <IconButton
                        sx={{
                            minWidth: 0,
                            minHeight: 0,
                            width: 16,
                            height: 16,
                            color: relation.active ? relation.color : '#eeeeee',
                        }}
                    >
                        <CircleIcon fontSize="small" />
                    </IconButton>
                    <Typography variant="caption">{generateItemTitle(relation.title, nodeGroupBy[0])}</Typography>
                </Stack>
            ))}
        </Stack>
    )
}

export default NetworNodeLegend
