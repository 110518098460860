import { useEffect, useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { InsightWidgetType, ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'
import InsightWidgetInterventionView from './views/InisghtWidgetInterventionView'
import InsightWidgetCardView from './views/InsightWidgetCardView'
import InsightWidgetInterventionAdvancedView from './views/advanced/InisghtWidgetInterventionAdvancedView'

type InsightWidgetProps = {
    Id: ReportWidgetType['id']
    isActive: boolean
    data: InsightWidgetType
    title: string
    onReady: () => void
    fontSizeScaleFactor: number
}

function InsightWidget({ Id, isActive, data, title, onReady, fontSizeScaleFactor: fontSizeScale }: InsightWidgetProps) {
    useEffect(() => {
        onReady()
    }, [])

    const fontSize = useMemo(() => {
        return Math.max(10, data.fontStyles.fontSize * fontSizeScale)
    }, [fontSizeScale, data.fontStyles.fontSize])

    return (
        <Stack
            sx={{
                height: '100%',
                position: 'relative',
            }}
        >
            {data.showTitle && (
                <Typography
                    width="100%"
                    paddingTop={1}
                    textAlign="center"
                    fontSize={18 * fontSizeScale}
                    fontWeight={600}
                >
                    {title}
                </Typography>
            )}

            {/* Content */}
            <Box flexGrow={1} sx={{ overflowY: 'auto' }} className="u-scrollbar">
                <Stack
                    sx={{
                        height: '100%',
                    }}
                >
                    {data.cachedData === null || Object.keys(data.cachedData).length === 0 ? (
                        <Box margin="auto">
                            <Typography variant="h6" color="textSecondary" textAlign="center">
                                {data.emptyDataMessage}
                            </Typography>
                        </Box>
                    ) : data.mode === 'intervention' ? (
                        data.interventionConfig.layout === 'advanced' ? (
                            <InsightWidgetInterventionAdvancedView
                                insightWidget={data}
                                widgetId={Id}
                                isActive={isActive}
                                fontSize={fontSize}
                                fontScaleFactor={fontSizeScale}
                            />
                        ) : (
                            <InsightWidgetInterventionView
                                insightWidget={data}
                                widgetId={Id}
                                isActive={isActive}
                                fontSize={fontSize}
                            />
                        )
                    ) : (
                        <InsightWidgetCardView
                            insightWidget={data}
                            widgetId={Id}
                            isActive={isActive}
                            fontSize={fontSize}
                        />
                    )}
                </Stack>
            </Box>
        </Stack>
    )
}

export default InsightWidget
