import { orderBy, round, uniq } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import {
    ReportDataSortOrderType,
    ReportDataSourceAttributeType,
    ReportDataSourceRowType,
    TableWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import {
    TableDefinition,
    TableDefinitionMode,
    TableDefinitionModeEachRow,
    TableDefinitionModeTopK,
    TableDefinitionModeAggregation,
    TableDefinitionModeTies,
    TableDefinitionEachRowDefaultValues,
    TableDefinitionTopKDefaultValues,
    TableDefinitionAggregationDefaultValues,
    TableDefinitionTiesDefaultValues,
    TableConfigViewMode,
    TableConfigGridViewDefaultValues,
    TableConfigCardViewDefaultValues,
    TableParsedDataRow,
    TableDefinitionModeGraphDefaultValues,
    TableDefinitionModeGraph,
    TableParsedDataColumn,
    TableBadgeType,
    TableConfigChipViewDefaultValues,
} from 'features/report-designer/widgets/table-widget/helpers/TableWidget.asset'
import {
    convertNodeAttributeToKeyString,
    groupDataSourcePanelToMap,
} from 'features/report-designer/helpers/reportDesigner.helper'
import { applyAggregation, formatValueAsLabel } from 'features/report-designer/helpers/reportDesigner.helper'

/*
 * Helper functions
 * =========================================
 */

export function getTableAttributes(tableDefinition: TableDefinition): ReportDataSourceAttributeType[] {
    const attributes: ReportDataSourceAttributeType[] = []

    switch (tableDefinition.mode) {
        case 'graph':
            const graphColumns = tableDefinition.columns.filter((_column) => _column.field !== null)
            attributes.push(...graphColumns.map((_column) => _column.field!))
            break
        case 'each-row':
            if (tableDefinition.idField !== null) {
                attributes.push(tableDefinition.idField)
            }

            if (tableDefinition.compareWith != null && tableDefinition.compareWith.type !== 'panel') {
                attributes.push(tableDefinition.compareWith)
            }

            const usableEachRowColumns = tableDefinition.columns.filter((_column) => _column.field !== null)
            attributes.push(...usableEachRowColumns.map((_column) => _column.field!))

            if (tableDefinition.header.avatar !== null) {
                attributes.push(tableDefinition.header.avatar)
            }

            if (tableDefinition.header.field !== null) {
                attributes.push(tableDefinition.header.field)
            }

            if (tableDefinition.header.secondaryField !== null) {
                attributes.push(tableDefinition.header.secondaryField)
            }

            break

        case 'top-k':
            if (tableDefinition.idField !== null) {
                attributes.push(tableDefinition.idField)
            }

            if (tableDefinition.value.field !== null) {
                attributes.push(tableDefinition.value.field)
            }

            if (tableDefinition.label.field !== null) {
                attributes.push(tableDefinition.label.field)
            }

            break

        case 'aggregation':
            if (tableDefinition.groupField !== null) {
                attributes.push(tableDefinition.groupField)
            }

            const usableAggregationColumns = tableDefinition.columns.filter((_column) => _column.field !== null)
            attributes.push(...usableAggregationColumns.map((_column) => _column.field!))

            break

        case 'ties':
            if (tableDefinition.nodesLabelField !== null) {
                attributes.push(tableDefinition.nodesLabelField)
            }

            break
    }

    return [...attributes]
}

export function sortTableRowData(
    data: TableParsedDataRow[],
    sortFieldKeyString: string,
    order: ReportDataSortOrderType = 'asc'
) {
    const numberGroup: TableParsedDataRow[] = []
    const dateGroup: TableParsedDataRow[] = []
    const stringGroup: TableParsedDataRow[] = []
    const naGroup: TableParsedDataRow[] = []
    const arrayGroup: TableParsedDataRow[] = []
    const undefinedGroup: TableParsedDataRow[] = []

    const na_variants = ['n/a', 'na', 'n.a.', 'n.a']

    // Step 1: Populate type groups based on row data type
    for (const _row of data) {
        const sortFieldValue = _row.cells[sortFieldKeyString].value

        if (sortFieldValue !== undefined) {
            // Number
            if (typeof sortFieldValue === 'number') {
                numberGroup.push(_row)
            }
            // Date
            else if (sortFieldValue instanceof Date) {
                dateGroup.push(_row)
            }
            // String
            else if (typeof sortFieldValue === 'string') {
                // N/A
                if (na_variants.includes(sortFieldValue.toLowerCase().trim())) {
                    naGroup.push(_row)
                } else {
                    stringGroup.push(_row)
                }
            }
            // Array
            else if (Array.isArray(sortFieldValue)) {
                arrayGroup.push(_row)
            }
        }
        // Rows that have "undefined" as value for the sort field or they don't have such a field at all.
        else {
            undefinedGroup.push(_row)
        }
    }

    const isAscending = order === 'asc'

    // Step 2: Sort each type group
    const sortTypeGroup = (group: TableParsedDataRow[]) => {
        return group.sort((a, b) => {
            const firstElementSortFieldValue = a.cells[sortFieldKeyString].value
            const secondElementSortFieldValue = b.cells[sortFieldKeyString].value

            if (typeof firstElementSortFieldValue === 'number') {
                return (firstElementSortFieldValue - (secondElementSortFieldValue as number)) * (isAscending ? 1 : -1)
            }

            if (firstElementSortFieldValue instanceof Date && secondElementSortFieldValue instanceof Date) {
                return (
                    ((firstElementSortFieldValue as Date).getTime() - (secondElementSortFieldValue as Date).getTime()) *
                    (isAscending ? 1 : -1)
                )
            }

            if (typeof firstElementSortFieldValue === 'string') {
                return (
                    firstElementSortFieldValue.localeCompare(secondElementSortFieldValue as string) *
                    (isAscending ? 1 : -1)
                )
            }

            return 0
        })
    }

    const sortedNumberGroup = sortTypeGroup(numberGroup)
    const sortedDateGroup = sortTypeGroup(dateGroup)
    const sortedStringGroup = sortTypeGroup(stringGroup)

    // Step 3: Join the sorted type groups based on the order of types
    const sortedMapArray = [
        ...sortedNumberGroup,
        ...sortedDateGroup,
        ...sortedStringGroup,
        ...naGroup,
        ...arrayGroup,
        ...undefinedGroup,
    ]

    return structuredClone(sortedMapArray)
}

export function formatTableCellValue(
    value: ReportDataSourceRowType[any],
    capitalize: 'none' | 'sentence' | 'each word' = 'none'
): string | number {
    // Strings
    if (!isNaN(Number(value))) {
        return Number(value)
    }
    if (typeof value === 'string') {
        const result = value.trim()
        switch (capitalize) {
            case 'sentence':
                return result.length > 0 ? result.charAt(0).toUpperCase() + result.slice(1) : ''
            case 'each word':
                return result
                    .split(/\s+/) // splits on one or more whitespace characters
                    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
                    .join(' ')
            default:
                return result
        }
    }
    // Numbers
    else if (typeof value === 'number') {
        return value
    }
    // Dates
    else if (value instanceof Date) {
        const momentString = moment(value).format('DD/MM/YYYY')

        return momentString.replaceAll('/', '.')
    }
    // Undefined values
    else {
        return '-'
    }
}

export const getTableDefinitionModeInitialValues = (mode: TableDefinitionMode) => {
    let defaults: TableDefinition | null = null

    switch (mode) {
        case 'each-row':
            defaults = TableDefinitionEachRowDefaultValues

            break

        case 'top-k':
            defaults = TableDefinitionTopKDefaultValues

            break

        case 'aggregation':
            defaults = TableDefinitionAggregationDefaultValues

            break

        case 'ties':
            defaults = TableDefinitionTiesDefaultValues

            break
        case 'graph':
            defaults = TableDefinitionModeGraphDefaultValues

            break

        default:
            break
    }

    return structuredClone(defaults)
}

export const getTableConfigViewModeInitialValues = (viewMode: TableConfigViewMode) => {
    let defaults: TableWidgetType['config'] | null = null

    switch (viewMode) {
        case 'grid':
            defaults = TableConfigGridViewDefaultValues

            break

        case 'card':
            defaults = TableConfigCardViewDefaultValues

            break
        case 'chip':
            defaults = TableConfigChipViewDefaultValues

            break

        default:
            break
    }

    return defaults
}

/*
 * Table data parser
 * =========================================
 */

type ParseTableDataParams = {
    filteredData: ReportDataSourceRowType[]
    definition: TableDefinition
}

export function parseTableData({ filteredData, definition }: ParseTableDataParams): TableWidgetType['parsedData'] {
    let parsedData: TableWidgetType['parsedData'] = null

    switch (definition.mode) {
        case 'each-row':
            parsedData = parseTableDataEachRow({
                filteredData,
                definition: definition as TableDefinitionModeEachRow,
            })

            break

        case 'top-k':
            parsedData = parseTableDataTopK({
                filteredData,
                definition: definition as TableDefinitionModeTopK,
            })

            break

        case 'aggregation':
            parsedData = parseTableDataAggregation({
                filteredData,
                definition: definition as TableDefinitionModeAggregation,
            })

            break

        case 'ties':
            parsedData = parseTableDataTies({
                filteredData,
                definition: definition as TableDefinitionModeTies,
            })
            break
        case 'graph':
            parsedData = parseTableDataGraph({
                filteredData,
                definition: definition as TableDefinitionModeGraph,
            })

            break

        default:
            break
    }

    return structuredClone(parsedData)
}

/*
 * Data row parsers
 * Notes:
 *  1.  In these functions, row entry properties which are in "_snake_case" are internal
 *      and do not have a corresponding column; for example: "_internal_id".
 * =========================================
 */

type DataParserParams<
    T extends
        | TableDefinitionModeEachRow
        | TableDefinitionModeTopK
        | TableDefinitionModeAggregation
        | TableDefinitionModeTies
        | TableDefinitionModeGraph
> = {
    filteredData: ReportDataSourceRowType[]
    definition: T
}

function parseTableDataEachRow({ filteredData, definition }: DataParserParams<TableDefinitionModeEachRow>) {
    if (definition.idField === null || definition.header.field === null) {
        // Returning null means that there was an error in parsing data or the definition is invalid.
        return null
    }

    const parsedData: TableWidgetType['parsedData'] = {
        //* The order of adding columns is important.
        columns: [],
        rows: [],
    }

    const columnValues: Record<string, number[] | string[]> = {}

    if (definition.header.avatar !== null) {
        const key = convertNodeAttributeToKeyString(definition.header.avatar)
        // Add corresponding column if it doesn't already exist.
        parsedData.columns.push({
            fieldType: 'avatar',
            field: key,
            type: 'string',
            title: 'Avatar',
        })
    }

    // Primary field
    if (definition.header.field !== null) {
        const key = convertNodeAttributeToKeyString(definition.header.field)
        parsedData.columns.push({
            fieldType: 'primary',
            field: key,
            // TODO: Assign a compatible type based on field value.
            // type: 'string',
            title: formatValueAsLabel(definition.header.field.field),
        })
    }

    // Secondary field
    if (definition.header.secondaryField !== null) {
        const key = convertNodeAttributeToKeyString(definition.header.secondaryField)
        // Add corresponding column if it doesn't already exist.
        parsedData.columns.push({
            fieldType: 'secondary',
            field: key,
            // TODO: Assign a compatible type based on field value.
            // type: 'string',
            title: formatValueAsLabel(definition.header.secondaryField.field),
        })
    }
    const benchmarks: Record<string, Map<ReportDataSourceRowType[any], number | null>> = {}
    // populate columns
    for (const _column of definition.columns) {
        if (_column.field === null) continue
        const key = convertNodeAttributeToKeyString(_column.field)

        if (parsedData.columns.findIndex((_column) => _column.field === key) !== -1) continue

        // Fill 'dynamic' columns.
        const tmpColumn: TableParsedDataColumn = {
            fieldType: 'dynamic',
            field: key,
            // TODO: Assign a compatible type based on row field value.
            // type: '',
            title: _column.title || formatValueAsLabel(_column.field.field),
            description: _column.description,
        }

        if (definition.compareWith != null && definition.compareWith.type !== 'panel') {
            const _benchmark: Map<ReportDataSourceRowType[any], number | null> = new Map()
            const groupKey = convertNodeAttributeToKeyString(definition.compareWith)
            const groupedData = groupDataSourcePanelToMap(filteredData, groupKey)

            for (const [_groupName, _groupData] of groupedData) {
                const aggregatedValue = applyAggregation({
                    method: 'avg',
                    data: _groupData,
                    field: key,
                })

                _benchmark.set(_groupName, aggregatedValue)
            }
            benchmarks[key] = _benchmark
        }

        if (_column.advanced.dataType === 'number' && _column.advanced.rankingSettings.isEnabled) {
            let tmpData = filteredData.map((x) => Number(x[key])).filter((x) => !isNaN(x))
            tmpData = uniq(tmpData)
            if (_column.advanced.valuePolarity === 'negative') {
                tmpData.sort((a, b) => a - b)
            } else {
                tmpData.sort((a, b) => b - a)
            }
            columnValues[key] = tmpData
        }

        parsedData.columns.push(tmpColumn)
    }

    for (const _row of filteredData) {
        const entry: TableParsedDataRow = {
            _internal_id: '',
            // Required field
            id: uuidv4(),
            cells: {},
        }

        // Row identifier
        entry._internal_id = formatTableCellValue(_row[convertNodeAttributeToKeyString(definition.idField)])
            .toString()
            .toLowerCase()

        if (definition.compareWith != null && definition.compareWith.type !== 'panel') {
            const key = convertNodeAttributeToKeyString(definition.compareWith)
            entry._compare_group = _row[key]
        }

        // Avatar field
        if (definition.header.avatar !== null) {
            const key = convertNodeAttributeToKeyString(definition.header.avatar)

            entry.cells[key] = {
                value: formatTableCellValue(_row[key]),
            }
        }

        // Primary field
        if (definition.header.field !== null) {
            const key = convertNodeAttributeToKeyString(definition.header.field)

            const rowCellValue = formatTableCellValue(_row[key], 'each word')
            entry.cells[key] = {
                // Apply decimal precision if value is numerical

                value:
                    typeof rowCellValue === 'number' ? round(rowCellValue, definition.decimalPrecision) : rowCellValue,
            }
        }

        // Secondary field
        if (definition.header.secondaryField !== null) {
            const key = convertNodeAttributeToKeyString(definition.header.secondaryField)

            const rowCellValue = formatTableCellValue(_row[key])

            entry.cells[key] = {
                // Apply decimal precision if value is numerical
                value:
                    typeof rowCellValue === 'number' ? round(rowCellValue, definition.decimalPrecision) : rowCellValue,
            }
        }

        // Fill row data for each column
        for (let _column of definition.columns) {
            if (_column.field !== null) {
                const key = convertNodeAttributeToKeyString(_column.field)
                // Fill 'dynamic' columns.
                let parsedColumn = parsedData.columns.find((_column) => _column.field === key)!

                const rowCellValue = formatTableCellValue(_row[key])

                if (_column.advanced.dataType === 'number' && typeof rowCellValue === 'number') {
                    entry.cells[key] = {
                        // Apply decimal precision if value is numerical
                        value:
                            typeof rowCellValue === 'number'
                                ? round(rowCellValue, _column.decimalPrecision ?? definition.decimalPrecision)
                                : rowCellValue,
                    }
                    if (_column.advanced.valueMapping.isEnabled) {
                        const sortedBins = structuredClone(_column.advanced.valueMapping.bins)
                        sortedBins.sort((a, b) => (a.upperBound ?? 0) - (b.upperBound ?? 0))
                        const bin = sortedBins.find((x) => rowCellValue <= (x.upperBound ?? Infinity))
                        if (bin) {
                            entry.cells[key] = {
                                ...entry.cells[key],
                                mappedValue: bin.value ?? undefined,
                                color: bin.color,
                            }
                        }
                    }

                    entry.cells[key].badges = []

                    if (_column.advanced.rankingSettings.isEnabled) {
                        const sortedData = columnValues[key]
                        const rank = sortedData.findIndex((x) => x === rowCellValue) + 1
                        if (
                            ['both', 'top'].includes(_column.advanced.rankingSettings.mode) &&
                            rank <= _column.advanced.rankingSettings.limit
                        ) {
                            entry.cells[key].badges?.push({
                                color: 'success',
                                title: 'Top ' + _column.advanced.rankingSettings.limit,
                                icon: 'top',
                            })
                        } else if (
                            ['both', 'bottom'].includes(_column.advanced.rankingSettings.mode) &&
                            rank > sortedData.length - _column.advanced.rankingSettings.limit
                        ) {
                            entry.cells[key].badges?.push({
                                color: 'error',
                                title: 'Bottom ' + _column.advanced.rankingSettings.limit,
                                icon: 'bottom',
                            })
                        }
                    }
                    if (definition.compareWith != null) {
                        if (definition.compareWith.type === 'panel') {
                            const badge = getBenchmarkBadge(
                                'last measurement',
                                Number(formatTableCellValue(_row[`compare_${key}`])),
                                rowCellValue,
                                _column.advanced.range.max - _column.advanced.range.min,
                                0
                            )
                            if (badge !== null) entry.cells[key].badges?.push(badge)
                        } else {
                            const benchmark = benchmarks[key]?.get(entry._compare_group)
                            if (benchmark) {
                                const badge = getBenchmarkBadge(
                                    `${definition.compareWith.field} average`,
                                    benchmark,
                                    rowCellValue,
                                    _column.advanced.range.max - _column.advanced.range.min
                                )
                                if (badge !== null) entry.cells[key].badges?.push(badge)
                            }
                        }
                    }
                } else {
                    if (_column.advanced.valueMapping.isEnabled && _column.advanced.dataType === 'string') {
                        const mappedData = _column.advanced.valueMapping.categories.find(
                            (x) => x.key.toLowerCase() === rowCellValue.toString().toLowerCase()
                        )
                        if (mappedData) {
                            entry.cells[key] = {
                                value: mappedData.value,
                                color: mappedData.color,
                            }
                        } else {
                            entry.cells[key] = {
                                value: rowCellValue,
                            }
                        }
                    } else {
                        entry.cells[key] = {
                            // Apply decimal precision if value is numerical
                            value:
                                typeof rowCellValue === 'number'
                                    ? round(rowCellValue, _column.decimalPrecision ?? definition.decimalPrecision)
                                    : rowCellValue,
                        }
                    }
                }
            }
        }

        // Add the sort field to the entry if it doesn't already have it so later we can sort the data based on it.
        if (
            definition.sortField !== null &&
            Object.prototype.hasOwnProperty.call(entry.cells, convertNodeAttributeToKeyString(definition.sortField)) ===
                false
        ) {
            const sortFieldKey = convertNodeAttributeToKeyString(definition.sortField)

            const rowCellValue = formatTableCellValue(_row[sortFieldKey])

            entry.cells[sortFieldKey] = {
                // Apply decimal precision if value is numerical
                value:
                    typeof rowCellValue === 'number' ? round(rowCellValue, definition.decimalPrecision) : rowCellValue,
            }
        }

        parsedData.rows.push(entry)
    }

    // Sort
    if (definition.sortField !== null) {
        const sortFieldKey = convertNodeAttributeToKeyString(definition.sortField)

        parsedData.rows = sortTableRowData(parsedData.rows, sortFieldKey, definition.sortOrder)
    }

    return structuredClone(parsedData)
}

// Function to determine the badge based on the threshold

const getBenchmarkBadge = (
    benchmarkName: string,
    benchmark: number,
    value: number,
    range: number,
    threshold: number = 0.1
): TableBadgeType | null => {
    const FAR_ABOVE_THRESHOLD = benchmark + range * 0.2 // 20% of range above benchmark
    const ABOVE_THRESHOLD = benchmark + range * threshold // 10% of range above benchmark
    const BELOW_THRESHOLD = benchmark - range * threshold // 10% of range below benchmark
    const FAR_BELOW_THRESHOLD = benchmark - range * 0.2 // 20% of range below benchmark

    const percentageDifference = ((value - benchmark) / benchmark) * 100

    let title: string
    let color: TableBadgeType['color']
    let icon: TableBadgeType['icon']

    if (value > FAR_ABOVE_THRESHOLD) {
        title = `Significantly exceeds the ${benchmarkName} (${benchmark.toFixed(2)}) by ${percentageDifference.toFixed(
            2
        )}%`
        color = 'success'
        icon = 'double-up'
    } else if (value > ABOVE_THRESHOLD) {
        title = `Exceeds the ${benchmarkName} (${benchmark.toFixed(2)}) by ${percentageDifference.toFixed(2)}%`
        color = 'primary'
        icon = 'up'
    } else if (value < FAR_BELOW_THRESHOLD) {
        title = `Significantly below the ${benchmarkName} (${benchmark.toFixed(2)}) by ${Math.abs(
            percentageDifference
        ).toFixed(2)}%`
        color = 'error'
        icon = 'double-down'
    } else if (value < BELOW_THRESHOLD) {
        title = `Below the ${benchmarkName} (${benchmark.toFixed(2)}) by ${Math.abs(percentageDifference).toFixed(2)}%`
        color = 'warning'
        icon = 'down'
    } else {
        return null
    }

    return { title, color, icon }
}

function parseTableDataGraph({ filteredData, definition }: DataParserParams<TableDefinitionModeGraph>) {
    const parsedData: TableWidgetType['parsedData'] = {
        //* The order of adding columns is important.
        columns: [],
        rows: [],
    }
    // Primary field
    parsedData.columns.push({
        fieldType: 'primary',
        field: 'title',
        // TODO: Assign a compatible type based on field value.
        // type: 'string',
        title: 'Title',
    })

    // Fill the rest of columns (dynamicly defined)
    for (let _column of definition.columns) {
        if (_column.field !== null) {
            const key = convertNodeAttributeToKeyString(_column.field)

            // Fill 'dynamic' columns.
            if (parsedData.columns.findIndex((_column) => _column.field === key) === -1) {
                parsedData.columns.push({
                    fieldType: 'dynamic',
                    field: key,
                    // TODO: Assign a compatible type based on row field value.
                    // type: '',
                    title: _column.title || formatValueAsLabel(_column.field.field),
                    description: _column.description,
                })
            }
        }
    }

    // in Graph mode, we only have one row
    if (filteredData.length === 1) {
        const entry: TableParsedDataRow = {
            _internal_id: 'graph',
            // Required field
            id: uuidv4(),
            cells: {
                title: {
                    value: 'graph',
                },
            },
        }

        const _row = filteredData[0]

        // Fill row data for each column
        for (let _column of definition.columns) {
            if (_column.field !== null) {
                const key = convertNodeAttributeToKeyString(_column.field)

                // Fill 'dynamic' columns.
                if (parsedData.columns.findIndex((_column) => _column.field === key) === -1) {
                    parsedData.columns.push({
                        fieldType: 'dynamic',
                        field: key,
                        // TODO: Assign a compatible type based on row field value.
                        // type: '',
                        title: _column.title || formatValueAsLabel(_column.field.field),
                        description: _column.description,
                    })
                }

                entry.cells[key] = { value: formatTableCellValue(_row[key]) }
            }
        }

        parsedData.rows.push(entry)
    }

    return structuredClone(parsedData)
}

function parseTableDataTopK({ filteredData, definition }: DataParserParams<TableDefinitionModeTopK>) {
    if (definition.idField === null || definition.value.field === null) {
        // Returning null means that there was an error in parsing data or the definition is invalid.
        return null
    }

    const parsedData: TableWidgetType['parsedData'] = {
        //* The order of adding columns is important.
        columns: [],
        rows: [],
    }

    const sortedData = orderBy(
        filteredData,
        convertNodeAttributeToKeyString(definition.value.field),
        definition.sortOrder
    )

    // Select rows based on limit
    const topKRows = [...sortedData].slice(0, definition.limit)

    // Add 'rank' column
    if (definition.rank.isVisible) {
        parsedData.columns.push({
            fieldType: 'dynamic',
            field: 'rank',
            type: 'number',
            title: definition.rank.title || 'Rank',
        })
    }

    // Add 'label' column
    if (definition.label.field !== null) {
        parsedData.columns.push({
            fieldType: 'secondary',
            field: 'label',
            // TODO: Assign a compatible type based on field value.
            // type: 'string',
            title: definition.label.title || formatValueAsLabel(definition.label.field.field),
        })
    }

    // Add 'value' column
    if (definition.value.isVisible && definition.value.field !== null) {
        parsedData.columns.push({
            fieldType: 'primary',
            field: 'value',
            // TODO: Assign a compatible type based on field value.
            // type: 'string',
            title: definition.value.title || formatValueAsLabel(definition.value.field.field),
        })
    }

    parsedData.rows = topKRows.map((_row, idx) => {
        const entry: TableParsedDataRow = {
            _internal_id: '',
            // Required field
            id: uuidv4(),
            cells: {},
        }

        // Row identifier
        if (definition.idField !== null) {
            const key = convertNodeAttributeToKeyString(definition.idField)

            entry._internal_id = formatTableCellValue(_row[key]).toString().toLowerCase()
        }

        // Rank
        entry.cells['rank'] = { value: definition.sortOrder === 'asc' ? filteredData.length - idx : idx + 1 }

        // Value
        if (definition.value.field !== null) {
            const key = convertNodeAttributeToKeyString(definition.value.field)

            const rowCellValue = formatTableCellValue(_row[key])

            entry.cells['value'] = {
                // Apply decimal precision if value is numerical
                value:
                    typeof rowCellValue === 'number' ? round(rowCellValue, definition.decimalPrecision) : rowCellValue,
            }
        }

        // Label
        if (definition.label.field !== null) {
            const key = convertNodeAttributeToKeyString(definition.label.field)

            const rowCellValue = formatTableCellValue(_row[key])

            entry.cells['label'] = {
                // Apply decimal precision if value is numerical
                value:
                    typeof rowCellValue === 'number' ? round(rowCellValue, definition.decimalPrecision) : rowCellValue,
            }
        }

        return entry
    })

    return structuredClone(parsedData)
}

function parseTableDataAggregation({ filteredData, definition }: DataParserParams<TableDefinitionModeAggregation>) {
    if (definition.groupField === null) {
        // Returning null means that there was an error in parsing data or the definition is invalid.
        return null
    }

    const parsedData: TableWidgetType['parsedData'] = {
        //* The order of adding columns is important.
        columns: [],
        rows: [],
    }

    const dataGroups = groupDataSourcePanelToMap(filteredData, convertNodeAttributeToKeyString(definition.groupField))

    const groupFieldKey = convertNodeAttributeToKeyString(definition.groupField)

    if (parsedData.columns.findIndex((_column) => _column.field === groupFieldKey) === -1) {
        parsedData.columns.push({
            fieldType: 'primary',
            field: groupFieldKey,
            // TODO: Assign a compatible type based on row field value.
            // type: '',
            title: formatValueAsLabel(definition.groupField.field),
        })
    }

    for (const [_groupName, _groupData] of dataGroups) {
        const entry: TableParsedDataRow = {
            _internal_id: '',
            // Required field
            id: uuidv4(),
            cells: {},
        }

        // Row identifier
        entry._internal_id = formatTableCellValue(_groupName).toString().toLowerCase()

        // Group field value
        const rowCellValue = formatTableCellValue(_groupName)
        entry.cells[groupFieldKey] = {
            // Apply decimal precision if value is numerical
            value: typeof rowCellValue === 'number' ? round(rowCellValue, definition.decimalPrecision) : rowCellValue,
        }

        for (let _column of definition.columns) {
            if (_column.field !== null && _column.aggregation !== undefined) {
                const key = convertNodeAttributeToKeyString(_column.field)

                // We create a more unique field key to prevent duplication and overwrite of columns which have
                // the same field but different aggregation method.
                const compoundColumnKey = _column.aggregation + '_' + key

                // Fill 'dynamic' columns.
                if (parsedData.columns.findIndex((_column) => _column.field === compoundColumnKey) === -1) {
                    parsedData.columns.push({
                        fieldType: 'dynamic',
                        field: compoundColumnKey,
                        // TODO: Assign a compatible type based on row field value.
                        // type: '',
                        title: _column.title || formatValueAsLabel(_column.field.field),
                        description: _column.description,
                    })
                }

                const aggregatedValue = applyAggregation({
                    method: _column.aggregation,
                    data: _groupData,
                    field: key,
                })

                const rowCellValue = formatTableCellValue(aggregatedValue ?? undefined)

                entry.cells[compoundColumnKey] = {
                    // Apply decimal precision if value is numerical
                    value:
                        typeof rowCellValue === 'number'
                            ? round(rowCellValue, _column.decimalPrecision ?? definition.decimalPrecision)
                            : rowCellValue,
                }
            }
        }

        parsedData.rows.push(entry)
    }

    return structuredClone(parsedData)
}

export type ParsedTableDataRowTiesNode = {
    id: string | number
    node: string | number
    nodeLabel?: string | number
    relationship?: string
    tooltip?: string
    color?: string
}

function parseTableDataTies({ filteredData, definition }: DataParserParams<TableDefinitionModeTies>) {
    if (definition.nodesLabelField === null) {
        // Returning null means that there was an error in parsing data or the definition is invalid.
        return null
    }

    const parsedData: TableWidgetType['parsedData'] = {
        //* The order of adding columns is important.
        columns: [],
        rows: [],
    }

    function addNodeToRecord(key: string, labelValue: string) {
        return {
            _internal_id: key,
            id: uuidv4(),
            nodeLabel: formatTableCellValue(labelValue),
            relationships: {},
        }
    }

    function ensureRelationship(nodeRecord: (typeof nodesRecord)[string], relationship: string) {
        if (!nodeRecord.relationships[relationship]) {
            nodeRecord.relationships[relationship] = { in: [], out: [] }
        }
    }

    const relationships: Set<string> = new Set()
    const nodesRecord: Record<
        string,
        {
            _internal_id: string
            id: string
            nodeLabel: string | number
            relationships: Record<
                string,
                {
                    in: ParsedTableDataRowTiesNode[]
                    out: ParsedTableDataRowTiesNode[]
                }
            >
        }
    > = {}

    for (let _edge of filteredData) {
        const sourceKey = _edge.source as string
        const targetKey = _edge.target as string
        const relationship = _edge.relationship as string

        nodesRecord[sourceKey] = nodesRecord[sourceKey] || addNodeToRecord(sourceKey, _edge.sourceLabel as string)
        nodesRecord[targetKey] = nodesRecord[targetKey] || addNodeToRecord(targetKey, _edge.targetLabel as string)

        ensureRelationship(nodesRecord[sourceKey], relationship)
        ensureRelationship(nodesRecord[targetKey], relationship)

        const nodeBgColor = (definition.relations.find((x) => x.name === relationship)?.nodesBackgroundColor ??
            _edge.color) as string

        nodesRecord[sourceKey].relationships[relationship].out.push({
            id: _edge.id as string,
            node: targetKey,
            nodeLabel: formatTableCellValue(_edge.targetLabel).toString(),
            color: nodeBgColor,
            relationship: 'out',
        })

        nodesRecord[targetKey].relationships[relationship].in.push({
            id: _edge.id as string,
            node: sourceKey,
            nodeLabel: formatTableCellValue(_edge.sourceLabel).toString(),
            color: nodeBgColor,
            relationship: 'in',
        })
    }

    parsedData.columns.push({
        fieldType: 'primary',
        field: 'nodeLabel',
        type: 'string',
        title: 'Node',
    })

    for (let relationship of definition.relations) {
        if (relationship.isEnabled === false) continue
        const key = relationship.name
        relationships.add(key)
        parsedData.columns.push({
            fieldType: 'list',
            field: key,
            type: 'string',
            title: relationship.label ?? key,
        })
    }

    for (let nodeKey in nodesRecord) {
        const node = nodesRecord[nodeKey]
        const entry: TableParsedDataRow = {
            _internal_id: node._internal_id,
            id: uuidv4(),
            cells: {
                nodeLabel: {
                    value: node.nodeLabel,
                },
            },
        }

        // Initialize all known relationships with empty arrays
        for (let knownRelationship of relationships) {
            entry.cells[knownRelationship] = {
                value: [],
            }
        }

        for (let relationship in node.relationships) {
            if (definition.relations.find((x) => x.name === relationship)?.isEnabled === false) continue

            const { in: inRelationships, out: outRelationships } = node.relationships[relationship]

            // Concatenate arrays in the desired order: in, out, both
            const combinedRelationships = []

            switch (definition.edgeInclusivity) {
                case 'source':
                    combinedRelationships.push(...inRelationships)
                    break
                case 'target':
                    combinedRelationships.push(...outRelationships)
                    break
                case 'reciprocated':
                    const reciprocatedRelationships = inRelationships
                        .filter((inRel) => outRelationships.some((outRel) => outRel.node === inRel.node))
                        .map((inRel) => ({ ...inRel, relationship: 'both' }))
                    combinedRelationships.push(...reciprocatedRelationships)
                    break
                case 'all':
                    const inRelationshipsArray: ParsedTableDataRowTiesNode[] = []
                    const outRelationshipsArray: ParsedTableDataRowTiesNode[] = []
                    const bothRelationshipsArray: ParsedTableDataRowTiesNode[] = []

                    // First, add all outgoing relationships, and if there's a matching incoming, mark it as 'both'
                    for (const outRelation of outRelationships) {
                        const matchingInIndex = inRelationships.findIndex((rel) => rel.node === outRelation.node)
                        if (matchingInIndex !== -1) {
                            bothRelationshipsArray.push({ ...outRelation, relationship: 'both' })
                            // Remove the matching incoming so it's not processed again
                            inRelationships.splice(matchingInIndex, 1)
                        } else {
                            outRelationshipsArray.push(outRelation)
                        }
                    }
                    inRelationshipsArray.push(...inRelationships)

                    combinedRelationships.push(
                        ...[...bothRelationshipsArray, ...inRelationshipsArray, ...outRelationshipsArray]
                    )
                    break
            }

            entry.cells[relationship] = {
                value: combinedRelationships,
                hideLabel: definition.hideHeaders,
            }
        }

        if (definition.excludeEmptyNodes) {
            let hasRelationship = false
            for (let cellKey in entry.cells) {
                const cellValue = entry.cells[cellKey].value
                if (Array.isArray(cellValue) && cellValue.length > 0) {
                    hasRelationship = true
                    break
                }
            }
            if (!hasRelationship) continue
        }

        parsedData.rows.push(entry)
    }

    return structuredClone(parsedData)
}
