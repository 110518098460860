//* ======= Libraries
import { useState, useContext, useEffect, useMemo, useCallback } from 'react'
//* ======= Components and features
import PageCard from 'components/layouts/PageCard'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
//* ======= Custom logic
//* ======= Assets and styles
import { ProjectContext } from 'contexts/ProjectContext'
import { useParams } from 'react-router-dom'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { AllocationResult, AllocationType, GetAllocationService, UpdateAllocationService } from 'services/AllocationApi'
import DashboardSkeleton from 'pages/project-dashboard/DashboardSkeleton'
import BaseButton from 'components/base/BaseButton'
import CreateAllocationDialog from './Allocation.dialog'
import { quantileSeq } from 'mathjs'
import { Paper } from '@mui/material'
import { GetWidget } from 'services/WidgetApi'
import WebWorker from 'helpers/webWorkerHelper'
import { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext'
import GroupAllocationBoxplotChart from 'features/group-allocation/GroupAllocationBoxplotChart'
import GroupAllocationBarChart from 'features/group-allocation/GroupAllocationBarChart'
import ClassAllocationBoard from 'features/group-allocation/ClassAllocationBoard'
import { RootContext } from 'contexts/RootContext'
import ToastHelper from 'helpers/ToastHelper'
import ImportAllocationDialog from './ImportAllocation.dialog'
import { v4 as uuidv4 } from 'uuid'

export type ClassStatType = {
    students: Record<
        string,
        {
            id: string
            className: string
            name: string
            diversity: Record<string, { value: number; quarter: 'Q0' | 'Q1' | 'Q2' | 'Q3' }>
            network: Record<string, number>
            connections: Record<string, 'positive' | 'negative' | 'neutral'>
            positiveTies: number
            negativeTies: number
        }
    >
    diversity: Record<string, any[]>
    network: Record<
        string,
        {
            density: number
            reciprocity: number
            isolatedNodes: number
        }
    >
}

function AllocationDesigner() {
    const { pid, aid } = useParams()
    const { setPath } = useContext(RootContext)
    const { project, drawerContent } = useContext(ProjectContext)
    const [allocation, setAllocation] = useState<AllocationType | null>(null)
    const [networkViz, setNetworkViz] = useState<NetworkVizContextType | null>(null)
    const [selectedGroup, setSelectedGroup] = useState<string | null>(null)
    const [openAllocationDialog, setOpenAllocationDialog] = useState<boolean>(false)
    const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false)
    const [groupNames, setGroupNames] = useState<string[]>([])

    // fetch allocation
    useEffect(() => {
        fetchAllocation()
    }, [])

    const fetchAllocation = async () => {
        if (pid == null || aid == null) return
        const response = await GetAllocationService(pid, aid)
        if (response.success) {
            setPath((p) => [...p, { title: response.data.title }])
            setAllocation(response.data)
            const groupNames = Object.keys(response.data.result)
            setGroupNames(groupNames)
            if (groupNames.length > 0) {
                setSelectedGroup(groupNames[0])
            }
        }
    }

    // helper method to perform fetching network viz from server and unzip
    const fetchNetworkViz = useCallback(
        async (id: number) => {
            if (pid == null) throw new Error('Cannot load network viz')
            const response = await GetWidget(pid, id)
            if (response.success) {
                const worker = new WebWorker('workers/network/zip-worker.js')

                const data = (await worker.run({
                    mode: 'un-zip',
                    data: response.data.state,
                })) as any

                let state: NetworkVizContextType

                if (data.version) {
                    state = data.state
                } else {
                    state = data
                }
                setNetworkViz(state)
            } else {
                throw new Error('Failed to load network viz')
            }
        },
        [pid]
    )

    // when allocation is fetched, fetch network viz
    useEffect(() => {
        if (allocation?.config.selectedNetworkViz == null) return
        fetchNetworkViz(allocation?.config.selectedNetworkViz)
    }, [allocation?.config.selectedNetworkViz, fetchNetworkViz])

    const classesData: Record<string, ClassStatType> | null = useMemo(() => {
        const data: Record<string, ClassStatType> = {}

        if (!selectedGroup || !allocation || !allocation.result || !networkViz) return null
        const classes = allocation.result[selectedGroup]
        const { diversityFields, networks } = allocation.config
        const { nodes, analytics, edges } = networkViz

        const unAllocatedStudents = structuredClone(nodes)

        const networkNames = networks.map((network) => network.name.toLowerCase())
        const edgesArray = Object.values(edges)
        const nodeClassMap: Map<string, string> = new Map()

        const emptyNetworkRecord: ClassStatType['students']['string']['network'] = {}

        for (const network of networkNames) {
            emptyNetworkRecord[network] = 0
        }

        for (const className of Object.keys(classes)) {
            data[className] = {
                students: {},
                diversity: {},
                network: {},
            }

            const classMembers = classes[className].members
            for (let member of classMembers) {
                delete unAllocatedStudents[member]
                data[className].students[member] = {
                    name: nodes[member].name,
                    id: member,
                    className: className,
                    diversity: {},
                    network: structuredClone(emptyNetworkRecord),
                    positiveTies: 0,
                    negativeTies: 0,
                    connections: {},
                }
                nodeClassMap.set(member, className)
            }

            // Extract diversity field values
            for (const item of diversityFields) {
                data[className].diversity[item.attribute.field] = classMembers.map((memberId) => {
                    let value = null
                    if (item.attribute.type === 'basic') {
                        value = nodes[memberId][item.attribute.field]
                    } else if (item.attribute.type === 'analytic') {
                        value = analytics?.[item.attribute.relationship]?.nodes?.[memberId]?.[item.attribute.field]
                    }
                    data[className].students[memberId].diversity[item.attribute.field] = {
                        value: value ?? 0,
                        quarter: 'Q0',
                    }
                    return value
                })

                networkNames.forEach((network) => {
                    data[className].network[network] = {
                        density: 0,
                        reciprocity: 0,
                        isolatedNodes: 0,
                    }
                })
            }
            // Compute quartiles for each diversity field
            for (const item of diversityFields) {
                if (item.type !== 'numeric') continue
                const cleanedValues = data[className].diversity[item.attribute.field].filter((value) => !isNaN(value)) // Assuming this cleans the values
                if (cleanedValues.length === 0) continue
                const quantiles = quantileSeq(cleanedValues, [1 / 4, 1 / 2, 3 / 4]) as Array<number>

                for (const memberId of classMembers) {
                    const value = data[className].students[memberId].diversity[item.attribute.field].value
                    if (value < quantiles[0]) {
                        data[className].students[memberId].diversity[item.attribute.field].quarter = 'Q0'
                    } else if (value < quantiles[1]) {
                        data[className].students[memberId].diversity[item.attribute.field].quarter = 'Q1'
                    } else if (value < quantiles[2]) {
                        data[className].students[memberId].diversity[item.attribute.field].quarter = 'Q2'
                    } else {
                        data[className].students[memberId].diversity[item.attribute.field].quarter = 'Q3'
                    }
                }
            }
        }

        // Compute density and reciprocity
        edgesArray.forEach((edge) => {
            const relationship = edge.relationship.toLowerCase()
            const sourceClass = nodeClassMap.get(edge.source)
            const targetClass = nodeClassMap.get(edge.target)

            if (!sourceClass || !targetClass) return

            if (networkNames.includes(relationship)) {
                const mode =
                    allocation.config.networks.find((x) => x.name.toLowerCase() === relationship)?.mode ?? 'neutral'

                data[sourceClass].students[edge.source].connections[edge.target] = mode
                data[nodeClassMap.get(edge.target)!].students[edge.target].connections[edge.source] = mode

                if (sourceClass !== targetClass) return

                data[sourceClass].network[relationship].density += 1

                data[sourceClass].students[edge.source].network[relationship] += 1
                data[sourceClass].students[edge.target].network[relationship] += 1
                if (mode === 'positive') {
                    data[sourceClass].students[edge.source].positiveTies += 1
                    data[sourceClass].students[edge.target].positiveTies += 1
                } else if (mode === 'negative') {
                    data[sourceClass].students[edge.source].negativeTies += 1
                    data[sourceClass].students[edge.target].negativeTies += 1
                }

                // Check for reciprocity
                if (
                    edgesArray.some(
                        (e) =>
                            e.source === edge.target &&
                            e.target === edge.source &&
                            e.relationship.toLowerCase() === relationship
                    )
                ) {
                    data[sourceClass].network[relationship].reciprocity += 1
                }
            }
        })

        // Finalize the density and reciprocity metrics
        for (const className of Object.keys(classes)) {
            const memberCount = classes[className].members.length

            networkNames.forEach((network) => {
                const networkData = data[className].network[network]
                networkData.density /= memberCount * (memberCount - 1)
                networkData.reciprocity /= networkData.density === 0 ? 1 : networkData.density // Normalize to prevent divide-by-zero issues
            })
        }

        // Compute isolated students for each class based on each network
        for (const className of Object.keys(classes)) {
            const classMembers = classes[className].members

            networkNames.forEach((network) => {
                const connectedMembers: Set<string> = new Set()

                edgesArray.forEach((edge) => {
                    if (edge.relationship.toLowerCase() === network) {
                        if (classMembers.includes(edge.source)) connectedMembers.add(edge.source)
                        if (classMembers.includes(edge.target)) connectedMembers.add(edge.target)
                    }
                })

                const isolatedCount = classMembers.length - connectedMembers.size
                data[className].network[network].isolatedNodes = isolatedCount
            })
        }

        // add unallocated students
        data['unallocated'] = {
            students: {},
            diversity: {},
            network: {},
        }

        for (const student of Object.keys(unAllocatedStudents)) {
            data['unallocated'].students[student] = {
                name: nodes[student].name,
                id: student,
                className: 'unallocated',
                diversity: {},
                network: structuredClone(emptyNetworkRecord),
                positiveTies: 0,
                negativeTies: 0,
                connections: {},
            }
        }

        return data
    }, [selectedGroup, allocation, networkViz])

    const changeGroup = (nodeId: string, from: string, to: string) => {
        if (selectedGroup == null) return
        setAllocation((pv) => {
            if (pv == null) return pv
            const tmp = { ...pv }
            if (tmp.result?.[selectedGroup]) {
                tmp.result[selectedGroup][from].members = tmp.result[selectedGroup][from].members.filter(
                    (x) => x !== nodeId
                )
                tmp.result[selectedGroup][to].members.push(nodeId)
            }
            return tmp
        })
    }

    const saveChanges = async () => {
        if (pid == null || aid == null || allocation?.result == null) return
        const toast = new ToastHelper({
            successMessage: 'Allocation updated successfully',
            errorMessage: 'Failed to update allocation',
        })
        try {
            const response = await UpdateAllocationService(pid, aid, { result: allocation.result })
            if (response.success) {
                toast.success()
            } else {
                toast.fail()
            }
        } catch {
            toast.fail()
        } finally {
            toast.close()
        }
    }

    const exportAllocation = () => {
        if (allocation == null) return
        const element = document.createElement('a')
        const file = new Blob([JSON.stringify(allocation.result)], { type: 'application/json' })
        element.href = URL.createObjectURL(file)
        element.download = `${allocation.title}.json`
        document.body.appendChild(element)
        element.click()
    }

    const openImportDialog = () => {
        setImportDialogOpen(true)
    }

    const importAllocation = (result: AllocationResult) => {
        setAllocation((pv) => {
            if (pv == null) return pv
            return {
                ...pv,
                result: result,
            }
        })
        setImportDialogOpen(false)
    }

    const addGroup = useCallback(() => {
        if (selectedGroup == null) return
        setAllocation((pv) => {
            if (pv == null) return pv
            const tmp = { ...pv }
            const newGroupId =
                Object.keys(tmp.result[selectedGroup])
                    .map((x) => parseInt(x))
                    .sort((a, b) => b - a)[0] + 1

            tmp.result[selectedGroup][newGroupId] = {
                members: [],
                stats: {},
                name: `New Group`,
            }
            return tmp
        })
    }, [selectedGroup, allocation])

    const updateGroupName = (index: string, name: string) => {
        if (selectedGroup == null) return
        setAllocation((pv) => {
            if (pv == null) return pv
            const tmp = structuredClone(pv)
            tmp.result[selectedGroup][index].name = name
            return tmp
        })
    }

    const deleteGroup = (index: string) => {
        if (selectedGroup == null) return
        setAllocation((pv) => {
            if (pv == null) return pv
            const tmp = structuredClone(pv)
            const students = tmp.result[selectedGroup][index].members
            if ('unallocated' in tmp.result[selectedGroup] === false) {
                tmp.result[selectedGroup]['unallocated'] = { members: [], stats: {}, name: 'Unallocated' }
            }
            tmp.result[selectedGroup]['unallocated'].members = [
                ...tmp.result[selectedGroup]['unallocated'].members,
                ...students,
            ]
            delete tmp.result[selectedGroup][index]
            return tmp
        })
    }

    if (allocation == null) return <DashboardSkeleton />

    return (
        <PageCard>
            <PageCard.Body>
                <Stack
                    height="100%"
                    sx={(theme) => ({
                        paddingBottom: 2,

                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.common.bg_1 : theme.palette.common.bg_3,
                    })}
                >
                    {/* Title and head controls
						========================================= */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                        sx={(theme) => ({
                            py: 1.25,
                            px: 3,

                            borderBottom: `1px solid ${theme.palette.common.border_2}`,
                        })}
                    >
                        {/* Title
							========================================= */}
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Typography fontSize={20} fontWeight={500} color="secondary">
                                {allocation?.title}
                            </Typography>
                        </Stack>

                        {/* Controls
							========================================= */}
                        <Stack direction="row" alignItems="center" gap={1}>
                            <BaseButton
                                variant="outlined"
                                color="primary"
                                onClick={(evt) => setOpenAllocationDialog(true)}
                            >
                                Configuration
                            </BaseButton>
                            <BaseButton variant="outlined" color="primary" onClick={(evt) => exportAllocation()}>
                                Export
                            </BaseButton>
                            <BaseButton variant="outlined" color="primary" onClick={(evt) => openImportDialog()}>
                                Import
                            </BaseButton>
                            <BaseButton variant="outlined" color="primary" onClick={saveChanges}>
                                Save
                            </BaseButton>
                        </Stack>
                    </Stack>

                    {/* Body
						========================================= */}
                    <Box
                        flexGrow={1}
                        sx={{
                            paddingTop: 1,
                            paddingLeft: 2,
                            paddingRight: 2,
                            overflow: 'hidden',
                        }}
                    >
                        {allocation.status === 'Failed' ? (
                            <Typography variant="h6">Failed to allocate</Typography>
                        ) : allocation.status === 'Pending' ? (
                            <Typography variant="h6">Allocation is pending</Typography>
                        ) : (
                            allocation.status === 'Completed' &&
                            allocation.result && (
                                <Stack direction="column" gap={2} height="100%">
                                    {groupNames.length > 1 && (
                                        <BaseSelectWithLabel
                                            label="Group"
                                            fullWidth
                                            options={groupNames.map((x) => ({
                                                label: x,
                                                value: x,
                                            }))}
                                            value={selectedGroup}
                                            onChange={(value) => setSelectedGroup(value)}
                                        />
                                    )}
                                    {selectedGroup && allocation.result[selectedGroup] && (
                                        <Stack direction="column" gap={2} sx={{ flexGrow: 1, overflow: 'hidden' }}>
                                            <Box
                                                sx={{
                                                    overflow: 'hidden',
                                                    flexGrow: 1,
                                                }}
                                                className="u-scrollbar"
                                            >
                                                {classesData && (
                                                    <ClassAllocationBoard
                                                        classesData={classesData}
                                                        changeGroup={changeGroup}
                                                        updateGroupName={updateGroupName}
                                                        addGroup={addGroup}
                                                        deleteGroup={deleteGroup}
                                                    />
                                                )}
                                            </Box>
                                            <Stack
                                                direction="row"
                                                gap={2}
                                                sx={{
                                                    overflow: 'hidden',
                                                    height: '20vh',
                                                    minHeight: 200,
                                                    width: '100%',
                                                }}
                                            >
                                                <Box component={Paper} sx={{ height: '100%', width: '50%' }}>
                                                    <GroupAllocationBoxplotChart data={classesData} />
                                                </Box>
                                                <Box component={Paper} sx={{ height: '100%', width: '50%' }}>
                                                    <GroupAllocationBarChart data={classesData} />
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    )}
                                </Stack>
                            )
                        )}
                    </Box>
                </Stack>

                <CreateAllocationDialog
                    open={openAllocationDialog}
                    onClose={() => setOpenAllocationDialog(false)}
                    defaultConfig={{
                        title: allocation.title,
                        config: allocation.config,
                    }}
                    mode="edit"
                    onCreate={(result) => {
                        setOpenAllocationDialog(false)
                    }}
                />

                <ImportAllocationDialog
                    open={importDialogOpen}
                    onClose={() => setImportDialogOpen(false)}
                    onImport={importAllocation}
                />
            </PageCard.Body>
        </PageCard>
    )
}

export default AllocationDesigner
