//* ======= Libraries
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { Stack, Typography, Tooltip, SvgIcon, Box } from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import { TableWidgetType } from 'features/report-designer/types/reportDesigner.types'
import {
    TableConfigCardView,
    TableParsedDataRow,
} from 'features/report-designer/widgets/table-widget/helpers/TableWidget.asset'
import { ParsedTableDataRowTiesNode } from 'features/report-designer/widgets/table-widget/helpers/TableWidget.helper'
//* ======= Assets and styles
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import TableNodeChip from './TableNodeChip'
import WarningIcon from '@mui/icons-material/Warning'
import InfoIcon from '@mui/icons-material/Info'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckIcon from '@mui/icons-material/Check'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import BalanceIcon from '@mui/icons-material/Balance'
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter'
import TableDataHeader from './TableDataHeader'

type CardDataContent = {
    hideLabel?: boolean
    label: string
    value: TableParsedDataRow['cells'][any]['value']
    valueTooltip?: string
    description?: string
    color?: string
    badges?: TableParsedDataRow['cells'][any]['badges']
}

type CardData = {
    id: string
    header: {
        primary: string
        secondary?: string
        avatar?: string
    }
    content: CardDataContent[]
}

type Props = {
    parsedData: TableWidgetType['parsedData']
    config: TableConfigCardView
    selectedRowId: TableWidgetType['selectedRowId']
    onSelectedRowChange?: (id: TableWidgetType['selectedRowId']) => void
    isActive: boolean
    fontSizeScaleFactor: number
    onReady?: () => void
}

export const BADGE_ICON: Record<string, ReactElement> = {
    up: <KeyboardArrowUpIcon />,
    down: <KeyboardArrowDownIcon />,
    'double-up': <KeyboardDoubleArrowUpIcon />,
    'double-down': <KeyboardDoubleArrowDownIcon />,
    center: <VerticalAlignCenterIcon />,
    warning: <WarningIcon />,
    info: <InfoIcon />,
    error: <ErrorIcon />,
    success: <CheckCircleIcon />,
    check: <CheckIcon />,
    top: <MilitaryTechIcon />,
    bottom: <NewReleasesIcon />,
    balance: <BalanceIcon />,

    'double-check': <CheckCircleOutlineIcon />,
}

function TableWidgetCardView({
    parsedData,
    config,
    selectedRowId,
    onSelectedRowChange,
    isActive,
    onReady,
    fontSizeScaleFactor,
}: Props) {
    const [cardsData, setCardsData] = useState<CardData[]>([])

    const [isInitialized, setIsInitialized] = useState(false)

    // Currently selected row
    const [activeRowIndex, setActiveRowIndex] = useState<number | null>(null)

    const activeDataRow = useMemo(() => {
        return activeRowIndex !== null ? cardsData[activeRowIndex] : undefined
    }, [activeRowIndex, cardsData])

    const generateCardsData = useCallback(
        (parsedData: TableWidgetType['parsedData']): CardData[] => {
            if (parsedData === null) {
                return []
            }

            const cardsList: CardData[] = []

            for (const _row of parsedData.rows) {
                let entry: CardData = {
                    id: _row._internal_id,
                    header: {
                        primary: '',
                    },
                    content: [],
                }

                for (const _column of parsedData.columns) {
                    switch (_column.fieldType) {
                        case 'primary':
                            entry = {
                                ...entry,
                                header: {
                                    ...entry.header,
                                    primary:
                                        (config.isPrimaryLabelVisible ? `${_column.title}: ` : '') +
                                        _row.cells[_column.field].value?.toString(),
                                },
                            }

                            break

                        case 'secondary':
                            entry = {
                                ...entry,
                                header: {
                                    ...entry.header,
                                    secondary: _row.cells[_column.field].value?.toString(),
                                },
                            }
                            break

                        case 'avatar':
                            entry = {
                                ...entry,
                                header: {
                                    ...entry.header,
                                    avatar: _row.cells[_column.field].value?.toString(),
                                },
                            }

                            break

                        case 'list':
                        case 'dynamic':
                            entry = {
                                ...entry,
                                content: [
                                    ...entry.content,
                                    {
                                        label: _column.title || _column.field,
                                        hideLabel: _row.cells[_column.field].hideLabel ?? false,
                                        value:
                                            _row.cells[_column.field].mappedValue !== undefined
                                                ? _row.cells[_column.field].mappedValue!
                                                : _row.cells[_column.field].value,
                                        valueTooltip:
                                            _row.cells[_column.field].mappedValue !== undefined
                                                ? _row.cells[_column.field].value.toString()
                                                : undefined,
                                        color: _row.cells[_column.field].color,
                                        badges: _row.cells[_column.field].badges,
                                        description: _column.description,
                                    },
                                ],
                            }

                            break

                        default:
                            break
                    }
                }

                cardsList.push(entry)
            }

            return [...cardsList]
        },
        [config.isPrimaryLabelVisible]
    )

    // Update the selected row in widget's store state and inform all connected widgets.
    const onActiveRowChange = useCallback(
        debounce((selectedRowId: string | null) => onSelectedRowChange && onSelectedRowChange(selectedRowId), 250),
        [onSelectedRowChange]
    )

    // Update cards data only if "parsedData" changes.
    useEffect(() => {
        const newCardsData = generateCardsData(parsedData)
        onReady && onReady()

        setCardsData([...newCardsData])
    }, [parsedData, generateCardsData])

    // If selected row changes, update the table selected row and scroll to that row
    useEffect(() => {
        if (selectedRowId !== null) {
            const targetRow = cardsData.find((_row) => _row.id === selectedRowId)

            // If grid data contains the selected row
            if (targetRow !== undefined) {
                // If a row was already selected
                if (activeRowIndex !== null && activeRowIndex !== -1) {
                    // Get the currently active row ID
                    const currentActiveRowId = cardsData[activeRowIndex]?.id

                    // If the store state selected row and current active row are different, update the active row index.
                    if (currentActiveRowId !== targetRow.id) {
                        const targetRowIndex = cardsData.findIndex((_row) => _row.id === targetRow.id)

                        setActiveRowIndex(targetRowIndex)
                    } else {
                        // The store state selected row and current active row are the same. Do nothing.
                    }
                }
                // If there were no selected rows before, update the current active row state
                else {
                    const targetRowIndex = cardsData.findIndex((_row) => _row.id === targetRow.id)

                    setActiveRowIndex(targetRowIndex)
                }
            }
            // If the selected row doesn't exist in grid data
            else {
                // Since the selected row is invalid for current data, we set the active row index to null
                if (activeRowIndex !== null) {
                    setActiveRowIndex(null)
                }
            }
        }
        // No selected row
        else {
            if (activeRowIndex === null) {
                setActiveRowIndex(0)
            }
        }
        setIsInitialized(true)
    }, [selectedRowId, cardsData])

    const headerData = useMemo(() => {
        return cardsData.map((_item) => {
            return {
                id: _item.id,
                title: _item.header.primary,
                subtitle: _item.header.secondary,
                avatar: _item.header.avatar,
            }
        })
    }, [cardsData])

    const fontSize = useMemo(() => {
        return config.fontStyles.fontSize * fontSizeScaleFactor
    }, [fontSizeScaleFactor, config.fontStyles.fontSize])

    return isInitialized === false ? null : activeRowIndex === null ||
      activeRowIndex === -1 ||
      activeDataRow === undefined ? (
        /*  Invalid selected row view
            ========================================= */
        <Stack
            alignItems="center"
            justifyContent="center"
            gap={2}
            sx={(theme) => ({
                height: '100%',
                minHeight: 0,
                padding: theme.spacing(2),
            })}
        >
            <Typography fontSize={fontSize} fontWeight={500} sx={{ fontFamily: config.fontStyles.fontFamily }}>
                No data for the selected item.
            </Typography>

            {/* <BaseButton label="Reset" onClick={(evt) => setActiveRowIndex(0)} variant="outlined" /> */}
        </Stack>
    ) : (
        /*  Main view
            ========================================= */
        <Stack
            gap={1}
            sx={(theme) => ({
                height: '100%',
                minHeight: 0,
                padding: theme.spacing(1),
            })}
        >
            {/* Header
                ========================================= */}
            {config.hasHeader && (
                <TableDataHeader
                    activeRowIndex={activeRowIndex}
                    updateActiveRowIndex={(updatedIndex) => {
                        // Cancel any pending, debounced store "selectedRowId" update.
                        onActiveRowChange.cancel()
                        setActiveRowIndex(updatedIndex)
                        onActiveRowChange(cardsData[updatedIndex] !== undefined ? cardsData[updatedIndex].id : null)
                    }}
                    data={headerData}
                    canChangeRows={config.canChangeRows}
                    hasSearch={config.hasSearch}
                    disabled={!isActive}
                    avatarSize={(config.avatarSize ?? 40) * fontSizeScaleFactor}
                    headerStyle={{
                        ...config.headerStyle,
                        fontSize: config.headerStyle.fontSize * fontSizeScaleFactor,
                    }}
                />
            )}

            {/* Content
                ========================================= */}
            <Stack
                gap={0.5}
                sx={(theme) => ({
                    flexGrow: 1,

                    minHeight: 0,
                    paddingX: theme.spacing(1),
                    overflowY: 'auto',
                })}
                className="u-scrollbar"
            >
                {activeDataRow.content.map((_item, idx) => {
                    /*  List type values
                        ========================================= */
                    if (Array.isArray(_item.value) === true) {
                        if (Array.isArray(_item.value) && _item.value.length === 0) return null
                        return (
                            <Stack
                                key={`row-${_item.label}-${idx}`}
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                gap={1}
                            >
                                {/* Field label
                                    ========================================= */}
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                    sx={{
                                        minWidth: 0,
                                    }}
                                >
                                    {_item.hideLabel !== true && (
                                        <Typography
                                            fontSize={fontSize}
                                            fontWeight={600}
                                            noWrap
                                            sx={{ fontFamily: config.fontStyles.fontFamily }}
                                        >
                                            {_item.label + ':'}
                                        </Typography>
                                    )}

                                    {/* Description tooltip
                                        ========================================= */}
                                    {_item.description && _item.description !== '' && (
                                        <Tooltip title={_item.description}>
                                            <InfoIcon
                                                sx={(theme) => ({
                                                    flexShrink: 0,

                                                    width: 18,
                                                    height: 18,

                                                    color: theme.palette.primary.main,
                                                })}
                                            />
                                        </Tooltip>
                                    )}
                                </Stack>

                                {/* Items chips
                                    ========================================= */}
                                <Stack
                                    direction="row"
                                    flexWrap="wrap"
                                    gap={1}
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    {(_item.value as ParsedTableDataRowTiesNode[]).map((_node) => (
                                        <TableNodeChip
                                            color={_node.color ?? '#eeeeee'}
                                            name={_node.nodeLabel ? _node.nodeLabel + '' : `Node ${_node.id}`}
                                            selectedNode={activeDataRow.header.primary}
                                            tooltip={_node.tooltip}
                                            fontSize={fontSize}
                                            fontFamily={config.fontStyles.fontFamily}
                                            mode={
                                                _node.relationship === 'in'
                                                    ? 'in'
                                                    : _node.relationship === 'out'
                                                    ? 'out'
                                                    : 'both'
                                            }
                                            key={_node.id}
                                        />
                                    ))}
                                </Stack>
                            </Stack>
                        )
                    } else {
                        /*  Other type values
                            ========================================= */
                        return (
                            <Box key={`row-${_item.label}-${idx}`}>
                                {config.verticalLabels === true ? (
                                    <Stack direction="column" gap={0.5}>
                                        {/* Field label */}
                                        {config.hideLabels !== true && (
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                gap={1}
                                            >
                                                <Stack direction="row" alignItems="center" gap={1}>
                                                    <Typography
                                                        fontSize={fontSize}
                                                        fontWeight={600}
                                                        noWrap
                                                        sx={{ fontFamily: config.fontStyles.fontFamily }}
                                                    >
                                                        {_item.label + ':'}
                                                    </Typography>

                                                    {/* Description tooltip */}
                                                    {_item.description && _item.description !== '' && (
                                                        <Tooltip title={_item.description}>
                                                            <InfoIcon
                                                                sx={(theme) => ({
                                                                    flexShrink: 0,

                                                                    width: 18,
                                                                    height: 18,

                                                                    color: theme.palette.primary.main,
                                                                })}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Stack>
                                                <Stack direction="row" gap={0.5}>
                                                    {_item.badges?.map((_badge, idx) => (
                                                        <Tooltip key={idx} title={_badge.title}>
                                                            <SvgIcon
                                                                sx={(theme) => ({
                                                                    color: theme.palette[_badge.color].main,
                                                                    fontSize: 24,
                                                                })}
                                                            >
                                                                {_badge.icon ? BADGE_ICON[_badge.icon] : null}
                                                            </SvgIcon>
                                                        </Tooltip>
                                                    ))}
                                                </Stack>
                                            </Stack>
                                        )}

                                        {/* Field value */}
                                        <Typography
                                            fontSize={fontSize}
                                            sx={{
                                                color: _item.color ?? 'common.text_1',
                                                fontFamily: config.fontStyles.fontFamily,
                                            }}
                                        >
                                            {_item.value}
                                        </Typography>
                                    </Stack>
                                ) : (
                                    <Stack
                                        key={`row-${_item.label}-${idx}`}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        gap={2}
                                    >
                                        {/* Field label
                                    ========================================= */}
                                        {config.hideLabels !== true && (
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                gap={1}
                                                sx={{
                                                    flex: '0 0 55%',
                                                    minWidth: 0,
                                                }}
                                            >
                                                <Typography
                                                    fontSize={fontSize}
                                                    fontWeight={600}
                                                    noWrap
                                                    sx={{ fontFamily: config.fontStyles.fontFamily }}
                                                >
                                                    {_item.label + ':'}
                                                </Typography>

                                                {/* Description tooltip
                                        ========================================= */}
                                                {_item.description && _item.description !== '' && (
                                                    <Tooltip title={_item.description}>
                                                        <InfoIcon
                                                            sx={(theme) => ({
                                                                flexShrink: 0,

                                                                width: 18,
                                                                height: 18,

                                                                color: theme.palette.primary.main,
                                                            })}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </Stack>
                                        )}

                                        {/* Field value
                                    ========================================= */}
                                        <Tooltip title={_item.valueTooltip ?? _item.value?.toString()}>
                                            <Typography
                                                fontSize={fontSize}
                                                noWrap
                                                sx={{
                                                    flex: '1 0 0',
                                                    color: _item.color ?? 'common.text_1',
                                                    fontFamily: config.fontStyles.fontFamily,
                                                }}
                                            >
                                                {_item.value}
                                            </Typography>
                                        </Tooltip>

                                        {/* Benchmark
                                            ========================================= */}
                                        {/* {benchmarkItem(_item)} */}
                                        <Stack direction="row" gap={0.5}>
                                            {_item.badges?.map((_badge, idx) => (
                                                <Tooltip key={idx} title={_badge.title}>
                                                    <SvgIcon
                                                        sx={(theme) => ({
                                                            color: theme.palette[_badge.color].main,
                                                            fontSize: 24,
                                                        })}
                                                    >
                                                        {_badge.icon ? BADGE_ICON[_badge.icon] : null}
                                                    </SvgIcon>
                                                </Tooltip>
                                            ))}
                                        </Stack>
                                    </Stack>
                                )}
                            </Box>
                        )
                    }
                })}
            </Stack>
        </Stack>
    )
}

export default TableWidgetCardView
