import { useEffect, useState } from 'react'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import StyledDialog from 'components/dialog/StyledDialog'
import BaseButton from 'components/base/BaseButton'
import { GridColDef } from '@mui/x-data-grid-pro'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'

import BaseFilledTextField from 'components/base/BaseFilledTextField'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import WebWorker from 'helpers/webWorkerHelper'
import { formatFileName } from 'features/network-viz/helpers/DataFormatter'

const SampleMatrix = require('../../samples/networkviz.sample.edges.matrix.txt')
const SampleEdgeList = require('../../samples/networkviz.sample.edges.list.csv')

export interface AddEdgeModalProps {
    open: boolean
    setOpen: (val: boolean) => void
}

export function AddEdgeModal({ open, setOpen }: AddEdgeModalProps) {
    const {
        layoutKind,
        nodeRenders,
        nodes,
        edges,
        edgeStyle,
        edgeGroupBy,
        filters,
        nodeInteractionConfig,
        analyticsSettings,
    } = useNetworkVizContext()
    const contextDispatch = useNetworkVizDispatch()

    //states
    const [rows, setRows] = useState<Array<{ [key: string]: any }>>([])
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [sourceField, setSourceField] = useState<string>('source')
    const [targetField, setTargetField] = useState<string>('target')
    const [relationship, setRelationship] = useState<string>('')
    const [isLoading, setLoading] = useState<boolean>(false)
    const [hasData, setHasData] = useState<boolean>(false)
    const [headers, setHeaders] = useState<string[]>([])

    const onClose = () => {
        setRows([])
        setColumns([])
        setSourceField('source')
        setTargetField('target')
        setRelationship('')
        setLoading(false)
        setHasData(false)
        setHeaders([])
        setOpen(false)
    }

    const handleAddDataSetClick = () => {
        setLoading(true)
        if (hasData) {
            const worker = new WebWorker('workers/network/add-edges.js')
            worker
                .run({
                    nodeRenders,
                    nodes,
                    edges,
                    edgeStyle,
                    edgeGroupBy,
                    newEdges: rows,
                    relationship,
                    sourceField,
                    targetField,
                    layoutKind,
                    filters,
                    isDirected: nodeInteractionConfig.directed,
                    analyticsSettings,
                })
                .then((res: any) => {
                    const {
                        edgeRenders,
                        edges,
                        edgeLegend,
                        nodeRenders,
                        edgeStyle,
                        edgeSchema,
                        nodeAdjacentList,
                        filters,
                        analyticsSettings,
                    } = res
                    contextDispatch({
                        type: 'EDGE_ADD',
                        payload: {
                            edges,
                            edgeRenders,
                            edgeLegend,
                            edgeStyle,
                            edgeDataSchema: edgeSchema,
                            nodeRenders,
                            nodeAdjacentList,
                            filters,
                            analyticsSettings,
                        },
                    })
                })
                .catch(() => {
                    //ToDo
                })
                .finally(() => {
                    onClose()
                })
        }
    }

    const uploadAndParseNodes = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setLoading(true)
            const worker = new WebWorker('workers/network/parse-edge-worker.js')
            const file = event.target.files[0]
            worker
                .run({ fileName: file })
                .then((res: any) => {
                    setRelationship(formatFileName(file.name))
                    const { data, headers } = res
                    setColumns([...headers])
                    setHeaders(headers.map((h: any) => h.field))
                    setHasData(true)
                    setRows(data)
                })
                .catch((e) => {
                    //ToDo
                })
                .finally(() => setLoading(false))
        }
    }

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
            <DialogTitle>Add Edges</DialogTitle>
            <DialogContent sx={{ height: '80vh' }}>
                <Stack direction="column" height="90%">
                    <Stack direction="row" alignItems="center" gap={2} marginBottom={1}>
                        <BaseButton
                            variant="contained"
                            size="large"
                            label="Select File"
                            onClick={() => {
                                document.getElementById('select-edge-csv')?.click()
                            }}
                        />
                        <input
                            id="select-edge-csv"
                            type="file"
                            accept=".csv, .txt, .json"
                            onChange={uploadAndParseNodes}
                            style={{ display: 'none' }}
                        />
                        <Link href={SampleMatrix} target="_blank">
                            Download sample matrix file
                        </Link>
                        <Link href={SampleEdgeList} target="_blank">
                            Download sample Edge list file
                        </Link>
                    </Stack>
                    {hasData && (
                        <Stack flexGrow={1}>
                            <Stack direction="row" spacing={2} width="100%">
                                <BaseFilledTextField
                                    sx={{ flex: 0.25 }}
                                    label="Relation Name"
                                    required={true}
                                    value={relationship}
                                    onChange={(e) => setRelationship(e.target.value)}
                                />

                                <BaseSelectWithLabel
                                    sx={{ flex: 0.25 }}
                                    label="Source Field"
                                    value={sourceField}
                                    onChange={(v) => setSourceField(v)}
                                    options={[...headers]}
                                />

                                <BaseSelectWithLabel
                                    sx={{ flex: 0.25 }}
                                    label="Target Field"
                                    required={true}
                                    value={targetField}
                                    onChange={(v) => setTargetField(v)}
                                    options={[...headers]}
                                />
                            </Stack>
                            <StyledDataGrid
                                columns={columns}
                                rows={rows}
                                pagination={true}
                                density="compact"
                                getRowId={(r) => r._index}
                            />
                        </Stack>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose} color="warning" variant="outlined">
                    Close
                </BaseButton>
                <BaseButton
                    disabled={hasData === false}
                    onClick={handleAddDataSetClick}
                    color="primary"
                    variant="contained"
                >
                    Add
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}
