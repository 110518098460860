//* ======= Libraries
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Stack, Box } from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import {
    FilterRecordType,
    NetworkWidgetType,
    ReportDataSourceAttributeType,
    ReportDataSourceRowType,
    ReportNetworkDataSourceType,
    ReportWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import { FilterAttribiuteType, NodeGroupStyleType } from 'features/network-viz/types/NetworkViz.types'
//* ======= Assets and styles
import HubIcon from '@mui/icons-material/Hub'
import NetworkVizContext, { NetworkVizContextType } from 'features/network-viz/context/NetworkVizContext'
import NetworkVizReportView from 'features/network-viz/NetworkVizReportView'
import NetworkStateHandler from './NetworkWidgetStateHandler'
import WebWorker from 'helpers/webWorkerHelper'
import { PartialDeep } from 'type-fest'

type Props = {
    data: NetworkWidgetType
    Id: ReportWidgetType['id']
    updateConnectedWidgets: (widgetId: ReportWidgetType['id'], selectedNode: string | null) => void
    onReady: () => void
}

const NetworkWidget = ({ updateConnectedWidgets, Id, data, onReady }: Props) => {
    const { activeSlideId, dataSources, addWidgetFilter, updateNetworkWidgetContent } = useReportStore((store) => ({
        dataSources: store.dataSources,
        activeSlideId: store.activeSlideId,
        updateNetworkWidgetContent: store.updateNetworkWidgetContent,
        addWidgetFilter: store.addWidgetFilter,
    }))

    const [networkVizContext, setNetworkVizContext] = useState<NetworkVizContextType | null>(null)

    useEffect(() => {
        const setupNetworkVizContext = async () => {
            if (dataSources && data.networkDatasourceId !== -1) {
                const selectedNetworkDatasource = structuredClone(
                    dataSources.find((x) => x.id === data.networkDatasourceId && x.mode === 'network')
                ) as ReportNetworkDataSourceType

                if (selectedNetworkDatasource) {
                    const presetKey = data.selectedPreset || 'default'

                    let presetData = structuredClone(selectedNetworkDatasource.presets[presetKey])

                    let legendConfig = structuredClone(selectedNetworkDatasource.networkContext.legend)
                    let nodeGroupInfo = structuredClone(selectedNetworkDatasource.networkContext.nodeGroupInfo)

                    // sync the nodeStyle and nodeRenders with the showLabels property
                    if (data.showLabels !== undefined) {
                        const newNodeStyle = presetData.nodeStyle
                        for (let group in newNodeStyle) {
                            newNodeStyle[group].label.show = data.showLabels
                        }
                        const newNodeRenders = presetData.nodeRenders

                        newNodeRenders.forEach((nodeRender) => {
                            if (nodeRender.label) {
                                nodeRender.label.show = data.showLabels ?? false
                            }
                            if (data.showLabels) nodeRender.attributes.label = nodeRender.label?.formatter
                            else nodeRender.attributes.label = undefined
                        })

                        presetData = {
                            ...presetData,
                            nodeRenders: newNodeRenders,
                            nodeStyle: newNodeStyle,
                        }
                    }

                    // sync the sizeScale with the selected field
                    if (data.sizeScale.enabled && data.sizeScale.selectedField !== undefined) {
                        const newGNS = structuredClone(presetData.nodeStyle)

                        let updatedSizeScale: Partial<NodeGroupStyleType['sizeScale']> = {}

                        if (data.sizeScale.selectedField === null) {
                            updatedSizeScale = {
                                enabled: false,
                            }
                        } else if (data.sizeScale.selectedField.type === 'analytic') {
                            updatedSizeScale = {
                                enabled: true,
                                attribute: {
                                    field: data.sizeScale.selectedField.field,
                                    relationship: data.sizeScale.selectedField.relationship,
                                    source: 'analytic',
                                },
                            }
                        } else if (data.sizeScale.selectedField.type === 'basic') {
                            updatedSizeScale = {
                                enabled: true,
                                attribute: {
                                    field: data.sizeScale.selectedField.field,
                                    source: 'info',
                                },
                            }
                        } else {
                            return
                        }

                        for (let g in newGNS) {
                            newGNS[g].sizeScale = {
                                ...newGNS[g].sizeScale,
                                ...updatedSizeScale,
                            }
                        }

                        const worker = new WebWorker('workers/network/change-node-style-worker.js')

                        try {
                            const res: any = await worker.run({
                                nodes: selectedNetworkDatasource.networkContext.nodes,
                                nodeRenders: presetData.nodeRenders,
                                dataSchema: presetData.nodeDataSchema.fields,
                                analytics: presetData.analytics,
                                nodeStyle: newGNS,
                            })

                            presetData.nodeRenders = res
                            presetData.nodeStyle = newGNS
                        } catch (e) {}
                    }

                    // sync the groupBy with the selected field
                    if (data.groupBy.enabled && data.groupBy.selectedField !== undefined) {
                        let updatedGroupby: NetworkVizContextType['nodeGroupBy'] = []

                        if (data.groupBy.selectedField === null) {
                            updatedGroupby = []
                        } else if (data.groupBy.selectedField.type === 'analytic') {
                            updatedGroupby = [
                                {
                                    field: data.groupBy.selectedField.field,
                                    relationship: data.groupBy.selectedField.relationship,
                                    source: 'analytic',
                                },
                            ]
                        } else if (data.groupBy.selectedField.type === 'basic') {
                            updatedGroupby = [
                                {
                                    field: data.groupBy.selectedField.field,
                                    source: 'info',
                                },
                            ]
                        } else {
                            return
                        }

                        const worker = new WebWorker('workers/network/group-by-nodes.js')
                        try {
                            const res = (await worker.run({
                                nodeRenders: presetData.nodeRenders,
                                edgeRenders: presetData.edgeRenders,
                                nodes: selectedNetworkDatasource.networkContext.nodes,
                                groupBys: updatedGroupby,
                                nodeStyle: presetData.nodeStyle,
                                analytics: presetData.analytics,
                                dataSchema: presetData.nodeDataSchema.fields,
                            })) as any

                            presetData.nodeRenders = res.nodeRenders
                            presetData.nodeStyle = res.nodeStyle
                            legendConfig = {
                                ...legendConfig,
                                nodes: res.nodeLegend,
                            }
                            presetData.nodeGroupBy = updatedGroupby
                            nodeGroupInfo = res.nodeGroupInfo
                        } catch (e) {}
                    }

                    setNetworkVizContext({
                        ...selectedNetworkDatasource.networkContext,
                        ...presetData,
                        legend: legendConfig,
                        nodeGroupInfo,
                        networkVizKind: data.displayMode,
                        nodeInteractionConfig: data.focusMode
                            ? {
                                  ...selectedNetworkDatasource.networkContext.nodeInteractionConfig,
                                  hideNonAdjacentNodesOnSelect: true,
                                  dimOtherNodesOnSelect: true,
                              }
                            : selectedNetworkDatasource.networkContext.nodeInteractionConfig,
                        selectedItem: data.selectedNode === null ? null : { mode: 'node', id: data.selectedNode },
                        status: 'loading',
                        keyFrames: presetData.keyFrames ?? [],
                        onlyShowReciprocatedEdges: presetData.onlyShowReciprocatedEdges ?? false,
                        dimOneWayEdges: presetData.dimOneWayEdges ?? false,
                        highlightReciprocatedEdges: presetData.highlightReciprocatedEdges ?? false,
                    })
                }
            } else {
                onReady()
            }
        }
        setupNetworkVizContext()
    }, [dataSources, data.networkDatasourceId, data.selectedPreset])

    const updateWidget = (data: NetworkWidgetType, disableTracking: boolean = false) => {
        if (activeSlideId === null) return

        updateNetworkWidgetContent({
            slideId: activeSlideId,
            widgetId: Id,
            data: data,
            disableTracking,
        })
    }

    const filters = useMemo(() => {
        const _filters: NetworkVizContextType['filters'] = []
        for (let filterKey in data.filters) {
            const filters = data.filters[filterKey]
            if (filters === null) continue

            for (let filter of filters) {
                if (
                    filter === null ||
                    filter.value == null ||
                    filter.field == null ||
                    (Array.isArray(filter.value) && filter.value.length === 0)
                ) {
                    continue
                }
                const attribute: FilterAttribiuteType =
                    filter.field.type === 'analytic'
                        ? {
                              source: 'analytic',
                              relationship: filter.field.relationship || '',
                              field: filter.field.field,
                          }
                        : {
                              source: 'info',
                              field: filter?.field.field || '',
                          }

                if (filter.operator === 'containsAny' || filter.operator === 'notContainsAny') {
                    _filters.push({
                        id: filterKey,
                        filterMode: 'hide',
                        type: 'compare',
                        attribute,
                        operator: filter.operator,
                        value: filter.value,
                    })
                } else if (filter.operator === 'between') {
                    // ToDo : Implement between operator
                } else {
                    _filters.push({
                        id: filterKey,
                        filterMode: 'hide',
                        type: 'compare',
                        attribute,
                        operator: filter.operator,
                        value: filter.value,
                    })
                }
            }
        }
        return _filters
    }, [data.filters])

    const onSelectedNodeChange = useCallback(
        (selectedNode: NetworkWidgetType['selectedNode']) => {
            updateConnectedWidgets(Id, selectedNode)
        },
        [Id, updateConnectedWidgets]
    )

    return data.networkDatasourceId === -1 ? (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                height: '100%',
                padding: 1,
            }}
        >
            <HubIcon
                sx={(theme) => ({
                    width: '90%',
                    height: '90%',

                    color: theme.palette.common.fill_2,
                })}
            />
        </Stack>
    ) : (
        /*	Player wrapper
            ========================================= */
        <Box
            sx={{
                width: '100%',
                height: '100%',
            }}
        >
            <NetworkVizContext>
                <NetworkVizReportView
                    legend={data.legend}
                    filters={filters}
                    context={networkVizContext}
                    hasSearch={data.displaySearchBar}
                    sizeSacle={data.sizeScale}
                    focusMode={data.focusMode}
                    hideActionBar={data.hideActionBar}
                    filterBehavior={{
                        updateLayout: data.runLayoutAfterFilter,
                    }}
                    onShowLabelsChange={(showLabels: boolean) => {
                        updateWidget({
                            ...data,
                            showLabels: showLabels,
                        })
                    }}
                    onSizeScaleChange={(sizeScale) => {
                        updateWidget({
                            ...data,
                            sizeScale: {
                                ...data.sizeScale,
                                selectedField: sizeScale,
                            },
                        })
                    }}
                    groupBy={data.groupBy}
                    onGroupByChange={(groupBy) => {
                        updateWidget({
                            ...data,
                            groupBy: {
                                ...data.groupBy,
                                selectedField: groupBy,
                            },
                        })
                    }}
                />
                <NetworkStateHandler
                    onUpdate={updateWidget}
                    onReady={onReady}
                    data={data}
                    updateConnectedWidgets={onSelectedNodeChange}
                />
            </NetworkVizContext>
        </Box>
    )
}

export default NetworkWidget
