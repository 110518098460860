import { useState } from 'react'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import StyledDialog from 'components/dialog/StyledDialog'
import BaseButton from 'components/base/BaseButton'
import { GridColDef, GridFilterModel, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import StyledDataGrid from 'components/data-grid/StyledDataGrid'
import { useNetworkVizContext, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import WebWorker from 'helpers/webWorkerHelper'
import { uniqBy } from 'lodash'

const SampleCsv = require('../../samples/netwrokviz.sample.nodes.csv')

export interface NetworkAddNodeModalProps {
    open: boolean
    setOpen: (val: boolean) => void
}

export default function NetworkAddNodeModal({ open, setOpen }: NetworkAddNodeModalProps) {
    const { nodes, nodeStyle, nodeGroupBy, edgeRenders, layoutKind, filters, analytics } = useNetworkVizContext()
    const contextDispatch = useNetworkVizDispatch()
    //states
    const [rows, setRows] = useState<Array<{ [key: string]: any }>>([])
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [idField, setIdField] = useState<string>('auto')
    const [hasData, setHasData] = useState<boolean>(false)
    const [headers, setHeaders] = useState<string[]>([])
    const [loading, setLoading] = useState(false)
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })

    const checkFilterItem = (row: { [key: string]: any }, filterItem: any): boolean => {
        const { columnField, operatorValue, value } = filterItem
        const cellValue = row[columnField]

        switch (operatorValue) {
            case 'equals':
                return cellValue.toString() === value
            case 'notEqual':
                return cellValue.toString() !== value
            case 'startsWith':
                return cellValue.toString().startsWith(value)
            case 'endsWith':
                return cellValue.toString().endsWith(value)
            case 'contains':
                return cellValue.toString().includes(value)
            default:
                return true
        }
    }

    const getFilteredRows = (
        rows: Array<{ [key: string]: any }>,
        filterModel: GridFilterModel
    ): Array<{ [key: string]: any }> => {
        const filteredRows = rows.filter((row: { [key: string]: any }) => {
            if (filterModel.logicOperator === 'or') {
                return filterModel.items.some((filterItem) => checkFilterItem(row, filterItem))
            } else {
                return filterModel.items.every((filterItem) => checkFilterItem(row, filterItem))
            }
        })

        return filteredRows
    }

    const handleAddDataSetClick = () => {
        if (hasData) {
            contextDispatch({ type: 'STATUS_UPDATE', payload: 'loading' })
            const worker = new WebWorker('workers/network/add-nodes.js')
            const filteredRows = getFilteredRows(rows, filterModel)
            worker
                .run({
                    nodes,
                    edgeRenders,
                    nodeGroupBy,
                    filters,
                    analytics,
                    nodeStyle,
                    newNodes: filteredRows,
                    idField,
                    layoutKind,
                })
                .then((res: any) => {
                    const { nodes, nodeRenders, nodeDataSchema, nodeInfoConfig, nodeStyle, nodeLegend } = res
                    contextDispatch({
                        type: 'NODE_ADD',
                        payload: {
                            nodes,
                            nodeDataSchema,
                            nodeRenders,
                            nodeInfoConfig,
                            nodeStyle,
                            nodeLegend,
                        },
                    })
                })
                .catch((e) => {
                    console.log(e)
                    //ToDo
                })
                .finally(() => {
                    contextDispatch({ type: 'STATUS_UPDATE', payload: 'ready' })
                    onClose()
                })
        }
    }

    const uploadAndParseNodes = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setLoading(true)
            const worker = new WebWorker('workers/report/parse-data-worker.js')

            worker
                .run({ fileName: event.target.files[0] })
                .then((response: any) => {
                    const data = response.data
                    const headers = uniqBy(response.headers, 'field') as any
                    setColumns([...headers])
                    setHeaders(headers.map((h: any) => h.field))
                    setHasData(true)
                    setRows(data)
                })
                .catch((e) => {
                    //ToDo
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const onClose = () => {
        setRows([])
        setColumns([])
        setIdField('auto')
        setLoading(false)
        setHasData(false)
        setHeaders([])
        setOpen(false)
    }

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
            <DialogTitle>Add Node</DialogTitle>
            <DialogContent sx={{ height: '80vh' }}>
                <Stack direction="column" height="90%">
                    <Stack direction="row" alignItems="center" gap={2} marginBottom={1}>
                        <BaseButton
                            variant="contained"
                            size="large"
                            label="Select File"
                            onClick={() => {
                                document.getElementById('select-node-csv')?.click()
                            }}
                        />
                        <input
                            id="select-node-csv"
                            type="file"
                            accept=".csv, .txt, .json"
                            onChange={uploadAndParseNodes}
                            style={{ display: 'none' }}
                        />
                        <Link href={SampleCsv} target="_blank">
                            Download sample CSV file
                        </Link>
                    </Stack>
                    {hasData && (
                        <Stack flexGrow={1}>
                            <Stack direction="row" spacing={2} width="100%">
                                <BaseSelectWithLabel
                                    sx={{ flex: 0.25 }}
                                    label="ID"
                                    required={true}
                                    value={idField}
                                    onChange={(v) => setIdField(v)}
                                    options={[{ label: 'Auto Generate', value: 'auto' }, ...headers]}
                                />
                            </Stack>
                            <StyledDataGrid
                                columns={columns}
                                filterModel={filterModel}
                                onFilterModelChange={(model) => {
                                    setFilterModel(model)
                                }}
                                pagination={true}
                                rows={rows}
                                density="compact"
                                getRowId={(r) => r._index}
                            />
                        </Stack>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose} color="warning" variant="outlined">
                    Close
                </BaseButton>
                <BaseButton onClick={handleAddDataSetClick} disabled={!hasData} color="primary" variant="contained">
                    Add
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}
