//* ======= Libraries
import React, { useState, useEffect, useCallback } from 'react'
import { isEqual } from 'lodash'
import { Box, Stack, Typography } from '@mui/material'
//* ======= Components and features
import TableWidgetGridView from 'features/report-designer/widgets/table-widget/views/TableWidgetGridView'
import TableWidgetCardView from 'features/report-designer/widgets/table-widget/views/TableWidgetCardView'
//* ======= Custom logic
import useReportStore, { ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import usePreviousValue from 'hooks/usePreviousValue'
import {
    TableWidgetType,
    ReportWidgetType,
    ReportDataSourceRowType,
} from 'features/report-designer/types/reportDesigner.types'
import { getDataSourceData } from 'features/report-designer/helpers/reportDesigner.helper'
import {
    getTableAttributes,
    parseTableData,
} from 'features/report-designer/widgets/table-widget/helpers/TableWidget.helper'
import TableWidgetChipView from './views/TableWidgetChipView'
//* ======= Assets and styles

type TableWidgetProps = {
    Id: ReportWidgetType['id']
    data: TableWidgetType
    updateConnectedWidgets: (widgetId: ReportWidgetType['id'], selectedNode: string | null) => void
    title: string
    isActive: boolean
    viewMode: ReportDesignerStoreStateType['viewMode']
    onReady: () => void
    fontSizeScaleFactor: number
}

function TableWidget({
    fontSizeScaleFactor,
    Id,
    data,
    updateConnectedWidgets,
    title,
    viewMode,
    isActive,
    onReady,
}: TableWidgetProps) {
    const { dataSources, activeSlideId, updateTableWidgetContent } = useReportStore((store) => ({
        dataSources: store.dataSources,
        activeSlideId: store.activeSlideId,
        updateTableWidgetContent: store.updateTableWidgetContent,
    }))

    const currentFilters = usePreviousValue(data.filters)

    const updateWidget = (data: TableWidgetType) => {
        if (activeSlideId === null) return

        updateTableWidgetContent({
            slideId: activeSlideId,
            widgetId: Id,
            data: data,
            disableTracking: true,
        })
    }

    const updateTableData = () => {
        const filteredData = getDataSourceData(
            dataSources,
            data.selectedDataSource,
            getTableAttributes(data.dataDefinition),
            data.filters,
            'compareWith' in data.dataDefinition ? data.dataDefinition.compareWith : null,
            {
                kind: 'table',
                details: data,
            }
        )

        updateWidget({
            ...data,
            parsedData: parseTableData({
                filteredData: filteredData || [],
                definition: data.dataDefinition,
            }),
        })
    }

    // Parse and update table data if dynamic filters change
    useEffect(() => {
        if (isEqual(currentFilters, data.filters) === false) {
            updateTableData()
        }
    }, [data.filters])

    const updateSelectedRow = useCallback(
        (selectedRowId: TableWidgetType['selectedRowId']) => {
            updateConnectedWidgets(Id, selectedRowId === null ? null : selectedRowId)
        },
        [Id, updateConnectedWidgets]
    )

    const clearAllFilters = () => {
        updateWidget({
            ...data,
            filters: null,
        })
    }

    useEffect(() => {
        if (data.parsedData === null) {
            onReady()
        }
    }, [])

    return data.parsedData === null ? (
        /*  Initial/Empty data view
            ========================================= */
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                height: '100%',
                padding: 1,
            }}
        >
            <Typography fontSize={14} textAlign="center">
                {data.config.emptyDataMessage}
            </Typography>
        </Stack>
    ) : (
        /*  Main view
            ========================================= */
        <Stack
            sx={{
                position: 'relative',

                width: '100%',
                height: '100%',
                minHeight: 0,
            }}
        >
            {/* ToDo Re-think this functionality (reset dynamic filters). */}
            {/* {!isFilterRecordEmpty(data.filters) && (
                <Box sx={{ position: 'absolute', right: 2, bottom: 2, zIndex: 2 }}>
                    <Tooltip title="Reset All Filters">
                        <IconButton onClick={clearAllFilters}>
                            <RestartAltIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )} */}

            {/* Title
                ========================================= */}
            {data.config.hasTitle && (
                <Typography
                    sx={(theme) => ({
                        flex: '0 0 auto',

                        width: '100%',
                        paddingTop: theme.spacing(1),
                        fontWeight: 600,
                        fontSize: 18 * fontSizeScaleFactor,
                        textAlign: 'center',
                    })}
                >
                    {title}
                </Typography>
            )}

            {/* Table data
                ========================================= */}
            <Box
                sx={(theme) => ({
                    flex: '1 0 0',

                    width: '100%',
                    minHeight: 0,
                })}
            >
                {data.config.viewMode === 'grid' ? (
                    <TableWidgetGridView
                        widgetId={Id}
                        slideId={activeSlideId}
                        parsedData={data.parsedData}
                        config={data.config}
                        onReady={onReady}
                        isActive={isActive}
                        selectedRowId={data.selectedRowId}
                        onSelectedRowChange={updateSelectedRow}
                        viewMode={viewMode}
                        fontSizeScaleFactor={fontSizeScaleFactor}
                    />
                ) : data.config.viewMode === 'card' ? (
                    <TableWidgetCardView
                        parsedData={data.parsedData}
                        config={data.config}
                        onReady={onReady}
                        selectedRowId={data.selectedRowId}
                        onSelectedRowChange={updateSelectedRow}
                        isActive={isActive}
                        fontSizeScaleFactor={fontSizeScaleFactor}
                    />
                ) : data.config.viewMode === 'chip' ? (
                    <TableWidgetChipView
                        parsedData={data.parsedData}
                        config={data.config}
                        onReady={onReady}
                        selectedRowId={data.selectedRowId}
                        onSelectedRowChange={updateSelectedRow}
                        viewMode={viewMode}
                        isActive={isActive}
                        fontSizeScaleFactor={fontSizeScaleFactor}
                    />
                ) : (
                    false
                )}
            </Box>
        </Stack>
    )
}

export default TableWidget
